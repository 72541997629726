.form-wish-done {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .label {
    position: relative;
    width: 13.5625rem;

    input {
      position: absolute;
      width: 1px;
      height: 1px;
      overflow: hidden;
    }
  }

  .description {
    margin-top: 0.875rem;
    width: 100%;
    max-width: 16.25rem;
    font-size: 0.625rem;
    line-height: 1.2;
    color: #757575;
    text-align: center;
  }

  .preview {
    position: relative;
    margin-top: 1.25rem;
    width: 18.3125rem;
    border-radius: 0.625rem;
    background-color: #d9d9d9;

    img {
      display: block;
      width: 100%;
      height: auto;
      border-radius: 0.625rem;
    }

    button {
      position: absolute;
      top: 0.375rem;
      right: 0.375rem;
      width: 2.25rem;
      height: 2.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 0;
      background-color: #fff;
      fill: #00b956;

      svg {
        width: 1.125rem !important;
        height: 1.125rem !important;
        fill: #00b956;
      }
    }
  }

  .submit {
    margin-top: 1.375rem;
    width: 9.375rem;
  }

  .error {
    margin-top: 1.375rem;
    font-size: 0.75rem;
    line-height: 1.2;
    color: $color-red;
  }
}

.profile {
  flex-grow: 1;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: $color-grey-background;

  &__wrapper {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    padding-top: 2.125rem;
    padding-bottom: 2.125rem;
    z-index: 1;
  }

  &__user {
    margin-right: 1.625rem;
    width: 28.5rem;
    min-height: 24.6rem;
    padding: 1.5rem 2rem 1.5rem 1.5rem;
    background-color: $color-white;
    border-radius: 1.25rem;
  }

  &__user-avatar {
    width: 6.75rem;
    height: 6.75rem;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__user-name {
    margin-bottom: 1rem;
    width: 100%;
    padding-top: 1.25rem;
    font-family: $font-main;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1;
    color: $color-black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__user-number {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $color-grey-dark;
  }

  //
  &__experience {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 34.375rem;
  }

  &__wishes,
  &__perform {
    width: 16.25rem;
    min-height: 16.5rem;
    padding: 1.5rem;
    background-color: $color-white;
    border-radius: 1.25rem;
  }

  &__wishes + &__perform {
    margin-left: 1.625rem;
  }

  &__card-icon,
  &__gallery-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: $color-green-light;

    svg {
      width: 2rem !important;
      height: 2rem !important;
      fill: $color-green !important;
    }
  }

  &__card-icon {
    margin-bottom: 4.0625rem;
  }

  &__card-title {
    margin-bottom: 0.5rem;
    font-family: $font-main;
    font-size: 1.4rem;
    font-weight: 500;
    font-style: normal;
    line-height: 1.5625rem;
    color: $color-black;
  }

  &__card-subtitle {
    font-family: $font-main;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $color-grey-dark;

    span {
      color: $color-black;
    }
  }

  &__card-button {
    margin-top: 0.75rem;
  }

  //
  &__gallery {
    margin-top: 1.125rem;
    width: 100%;
    padding: 1.5rem;
    background-color: $color-white;
    border-radius: 1.25rem;
  }

  &__gallery-row {
    display: flex;
    align-items: center;
  }

  &__gallery-header {
    width: 16.25rem;
    padding-left: 1rem;
  }

  &__gallery-title {
    font-size: 1.125rem;
    line-height: 1.5625rem;
    font-weight: 500;
    color: $color-black;
  }

  &__gallery-subtitle {
    margin-top: 0.375rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $color-grey-dark;
  }

  &__gallery-button {
    margin-left: auto;
  }
}

@media screen and (max-width: 1080px) {
  .profile {
    &__user {
      margin-right: 1.25rem;
      width: 27rem;
      padding: 1.5rem 1.375rem;
    }

    &__experience {
      width: 33.75rem;
    }

    &__wishes,
    &__perform {
      padding: 1.5rem 1.375rem;
    }

    &__wishes + &__perform {
      margin-left: 1.25rem;
    }
  }
}

@include media-tablet {
  .profile {
    // &:after {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   bottom: 0;
    //   left: 0;
    //   background-image: url("../../../assets/images/image-profile-mobile@2x.png");
    //   background-size: 14.25rem auto;
    //   background-position: 0 calc(100% + 2.8125rem);
    //   background-repeat: no-repeat;
    //   z-index: 0;
    // }

    &__wrapper {
      flex-direction: column;
      align-items: center;
      padding-top: 2rem;
      padding-bottom: 10rem;
    }

    &__user {
      margin-right: 0;
      margin-bottom: 1.5rem;
      min-height: 22rem;
    }

    &__experience {
      width: 27rem;
    }

    &__wishes,
    &__perform,
    &__gallery {
      position: relative;
      width: 100%;
      min-height: 6.25rem;
      padding: 1rem 1rem 1rem 8rem;
    }

    &__wishes + &__perform {
      margin-left: 0;
      margin-top: 1.5rem;
    }

    &__card-icon,
    &__gallery-icon {
      position: absolute;
      top: 1.125rem;
      left: 2rem;
      margin: 0;
    }

    &__card-title {
      margin-bottom: 0.25rem;
      font-size: 1.125rem;
      line-height: 1;
    }

    &__card-subtitle {
      font-size: 0.75rem;
      line-height: 1.25rem;
    }

    &__card-button {
      margin-top: 0.375rem;
    }

    //
    &__gallery {
      margin-top: 1.5rem;
    }

    &__gallery-row {
      flex-direction: column;
      align-items: flex-start;
    }

    &__gallery-header {
      width: 100%;
      padding-left: 0;
    }

    &__gallery-title {
      line-height: 1;
    }

    &__gallery-subtitle {
      margin-top: 0.25rem;
      font-size: 0.75rem;
      line-height: 1.25rem;
    }

    &__gallery-button {
      margin-left: 0;
      margin-top: 0.375rem;
    }
  }
}

@include media-mobile-medium {
  .profile {
    &__user {
      width: 22.1875rem;
      padding: 1rem 0.75rem 1.5rem 0.75rem;
      min-height: 19rem;
    }

    &__experience {
      width: 22.1875rem;
    }

    &__user-name {
      font-size: 1.1875rem;
      line-height: 1.5rem;
    }

    &__user-name {
      font-size: 0.75rem;
      line-height: 1.25rem;
    }

    &__wishes,
    &__perform {
      width: 22.1875rem;
    }
  }
}

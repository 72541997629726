.header--middle {
  position: relative;
  padding: 1.5rem 0;
  background: $color-white;

  &__container {
    max-width: 75.5rem;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    padding: 0;
  }

  &__logo {
    margin-right: auto;
    width: 9.6875rem;
    height: 1.625rem;

    &--link,
    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    margin-right: auto;
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 600;
    color: $color-black;
  }

  &__stat {
    display: flex;
    align-items: center;
    font-size: 0.9375rem;
    font-weight: 500;
    color: $color-green;

    &--done {
      margin-left: auto;
    }

    &--published {
      position: relative;
      margin-left: 1.0625rem;
      margin-right: 1rem;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: -0.6875rem;
        width: 1px;
        height: 1.125rem;
        background-color: #272727;
      }
    }

    span {
      margin-left: 4px;
      color: #731982;
    }
  }

  &__exit {
    margin-left: 0.5rem;
    width: 2.25rem;
    height: 2.25rem;
    padding: 0;
    border: 0;
    background-color: transparent;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

@include media-netbook {
  .header--middle {
    padding: 0.625rem 0;

    &__container {
      padding-left: 0.625rem;
      padding-right: 0.625rem;
    }

    &__logo {
      width: 6.3125rem;
      height: 1.25rem;
      margin: 0.25rem 0;
      margin-right: auto;
    }

    &__title {
      font-size: 1.3125rem;
      line-height: 1.5625rem;
    }

    &__action {
      min-height: 1.75rem;
      padding: 0.1875rem 1rem;
      font-size: 0.75rem;
    }

    &__stat {
      display: none;
    }
  }
}

.popover {
  max-width: 20rem !important;
}

.popover-body {
  padding: 0.5rem;
  font-size: 0.75rem;
  line-height: 1.2;
  color: $color-black;
}

.bs-popover-top > .popover-arrow {
  &:before,
  &:after {
    left: 50%;
  }
}

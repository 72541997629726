@mixin media-netbook {
  @media screen and (max-width: 1279px) {
    @content;
  }
}

@mixin media-tablet-wide {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin media-tablet {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

@mixin media-mobile {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin media-mobile-medium {
  @media screen and (max-width: 576px) {
    @content;
  }
}

@mixin media-mobile-small {
  @media screen and (max-width: 575px) {
    @content;
  }
}

.button-action-back {
  display: flex;
  align-items: center;
  padding: 0;
  border: 0;
  background-color: transparent;

  span {
    font-weight: 400;
    font-size: 0.9375rem;
    line-height: 1.25rem;
    color: $color-green;
  }

  svg {
    display: block;
    width: 1rem;
    height: 1rem;
    fill: $color-green;
  }
}

@import "./vars.scss";

html,
body {
  margin: 0;
  padding: 0;
  background-color: #f8f8f8;
  font-family: $font-main;
  font-size: 16px;
  font-size: calc(100vw / 1280 * 16);
  line-height: 1.3;
  color: $color-black;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

.container {
  max-width: 67.5rem;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@include media-netbook {
  html,
  body {
    font-size: 16px;
  }
}

@include media-mobile-small {
  html,
  body {
    font-size: calc(100vw / 375 * 16);
  }
}

.wish--tile--slide {
  width: 21rem;
  height: auto;

  &_end {
    width: 17.125rem;
  }

  &:not(:last-child) {
    margin-right: 1.5rem;
  }
}

.wish--tile {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
  background: $color-white;
  border-radius: 1.25rem;
  // border-top-right-radius: 0;
  // border-bottom-right-radius: 0;
  box-shadow: $white-card-shadow;
  overflow: hidden;

  &__remove-button {
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: 1.25rem;
    height: 1.25rem;

    &:hover {
      svg {
        fill: $color-green;
      }
    }

    svg {
      transition: fill 0.25s;
      fill: $color-grey;
    }
  }

  &__tags {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin: -0.625rem -0.3125rem 0;

    > * {
      margin: 0.625rem 0.3125rem 0;
    }
  }

  &__user {
    display: grid;
    grid-template-columns: 3.625rem 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0.25rem 1rem;
    grid-template-areas:
      "Avatar Name"
      "Avatar Location";
    margin-top: 0.5rem;

    &--avatar {
      grid-area: Avatar;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.625rem;
      height: 3.625rem;
      background: $color-grey-light;
      border-radius: 50%;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      svg {
        display: block;
        width: 1.875rem;
        height: 1.875rem;
      }
    }
    &--name {
      grid-area: Name;
      align-self: flex-end;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.25rem;
      letter-spacing: -0.15px;
      color: $color-black;
    }

    &--location {
      grid-area: Location;
      align-self: flex-start;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.25rem;
      color: $color-black;
    }
  }

  &__hashtags {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -0.3125rem;
    padding-top: 0.375rem;

    > * {
      margin: 0.625rem 0.3125rem 0;
    }
  }

  &__title {
    position: relative;
    margin-top: 1.25rem;
    margin-bottom: 1.5rem;
    height: auto;
    max-height: 4.5rem;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.5rem;
    z-index: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__actions {
    display: flex;
    align-items: flex-start;
    // display: grid;
    // gap: 1rem;
    // grid-template-columns: repeat(2, 1fr);
    margin-top: auto;
    min-height: 2.25rem;

    > * {
      // flex-grow: 1;
      // flex-shrink: 1;
      // flex-basis: 50%;
      // width: 50%;
      padding-left: 1rem;
      padding-right: 1rem;
      white-space: nowrap;

      &:nth-child(2) {
        margin-left: 1rem;
      }
    }
  }
}

@include media-netbook {
  .wish--tile--slide {
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

@include media-mobile {
  .wish--tile {
    &__title {
      margin-bottom: 1.625rem;
      letter-spacing: -0.1px;
    }
  }
}

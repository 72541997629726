.chat-quote {
  &__add-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0.625rem;
    height: 0.625rem;
    // background-color: red;

    img {
      display: block;
      width: 0.5rem;
      height: 0.5rem;
    }
  }

  &__body {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 1.75rem;
    padding: 0.25rem 1.75rem 0.25rem 2.125rem;
    font-size: 0.875rem;
    color: $color-black;
    background-color: #ccf1dd;
    border-top: 1px solid #d8d8d8;
  }

  &__indicator {
    display: block;
    position: absolute;
    left: 0.5rem;
    top: calc(50% - 0.5rem);
    width: 1rem;
    height: 1rem;
  }

  &__user-name {
    font-size: 0.75rem;
    line-height: 1.5;
    font-weight: 500;
    color: #808080;
  }

  &__user-message {
    width: 100%;
    font-size: 0.75rem;
    line-height: 1.5;
    color: #272727;
    color: #808080;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__rem-button {
    position: absolute;
    top: calc(50% - 0.375rem);
    right: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0.75rem;
    height: 0.75rem;

    img {
      width: 0.625rem;
      height: 0.625rem;
    }
  }
}

.chat-widget {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 1.5625rem;
  bottom: 1.5rem;
  height: 3.75rem;
  padding-right: 1rem;
  padding-left: 0.875rem;
  background-color: $color-white;
  border-radius: 1.875rem;
  box-shadow: 0px 0.25rem 0.6875rem rgba(128, 128, 128, 0.11);
  z-index: 10;
  display: none;

  &:hover {
    .chat-widget__hover {
      opacity: 1;
      visibility: visible;
    }
  }

  &__name {
    padding: 0.5rem 0;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $color-black;
  }

  .chat-header__avatars + &__name {
    margin-left: 1.8125rem;
  }

  &__hover {
    position: absolute;
    visibility: hidden;
    top: 0.0625rem;
    left: 50%;
    width: 6.875rem;
    height: 4.75rem;
    transform: translate(-50%, calc(-100% - 0.375rem));
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__close-button {
    position: fixed;
    right: 2rem;
    bottom: 1.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: $color-white;
    z-index: 10;

    svg {
      display: flex;
      width: 0.875rem;
      height: 0.875rem;
    }
  }
}

@include media-mobile-medium {
  .chat-widget {
    &__close-button {
      right: 1rem;
    }
  }
}

.article-tile {
  display: block;
  width: 21rem;
  border: 1px solid #dbdbdb;
  border-radius: 1rem;

  &__image {
    width: 100%;
    height: 11.875rem;
    background-color: #fff;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: 50% 0;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }
  }

  &__body {
    position: relative;
    width: 100%;
    height: 8.375rem;
    padding: 1.25rem;
    background-color: #fff;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;

    p {
      font-size: 1.0625rem;
      line-height: 1.3;
      font-weight: 600;
      color: #272727;
    }
  }

  &__link {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    display: flex;
    align-items: center;
    font-size: 0.9375rem;
    font-weight: 500;
    color: #00b956;

    &:hover {
      color: #00b956;
    }

    svg {
      fill: #00b956;
      display: block;
      height: 1rem;
      width: 1rem;
    }
  }
}

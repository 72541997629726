.btn-reset {
  border: 0;
  border-radius: 0;
  background-color: transparent;
  padding: 0;
}

.button--link {
  font-size: 0.875rem;
  line-height: 1.2;
  color: $color-black;
  text-decoration: underline;

  &:nth-child(n + 2) {
    margin-top: 1.25rem;
  }
}

@import "./button/button.scss";
@import "./ButtonText/ButtonText.scss";
@import "./ButtonModalClose/button-modal-close.scss";
@import "./buttonAction/buttonActionForward.scss";
@import "./buttonAction/buttonActionBack.scss";
@import "./buttonMobileFilter/buttonMobileFilter.scss";

.form-gallery-upload {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__files {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  &__previews {
    display: flex;
    justify-content: center;
  }

  &__image {
    margin: 0 0.375rem;
    width: 6.25rem;
    height: 6.25rem;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__info {
    font-size: 0.875rem;
    line-height: 1.3;
    color: $color-grey-dark;
    text-align: center;
  }

  &__error {
    margin-top: 1rem;
    font-size: 0.875rem;
    line-height: 1.3;
    color: $color-red;
    text-align: center;
  }

  &__button {
    margin-top: 1.6875rem;
  }
}

.header--top {
  position: relative;
  padding: 0.5rem 0;
  background: $color-white;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    border-bottom: 1px solid $color-grey-light;
  }

  &__container {
    display: flex;
    align-items: center;
    max-width: 75.5rem;
    padding: 0;
  }

  &__logo {
    width: 8.375rem;
    height: auto;

    &--link,
    svg,
    img {
      display: block;
      width: 100%;
      height: 100%;
    }

    img {
      object-fit: contain;
      object-position: center;
    }
  }

  &__nav {
    margin-left: 2.25rem;

    &--link {
      font-family: $font-main;
      font-weight: 500;
      font-size: 0.9375rem;
      line-height: 1.25rem;
      letter-spacing: -0.24px;
      color: $color-black;
      text-decoration: none;
      transition: color $transition;

      &:hover {
        color: #10e272;
        // text-decoration: underline;
      }

      &.active {
        color: #00b956;
        text-decoration: none;
      }

      &.hovered {
        // text-decoration: underline;
      }
    }

    &--link-purple {
      color: #731982;
    }

    &-wrapper {
      align-items: flex-start;

      > * + * {
        margin-left: 1.25rem;
      }
      display: flex;
    }
  }

  &__personal-data {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__personal-score-container {
    display: flex;
    flex-direction: column;
  }

  &__personal-score {
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.25rem;
    color: $color-grey-dark;
  }

  &__personal-points {
    margin-left: 0.75rem;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.25rem;
    color: $color-black;
  }

  &__personal-avatar {
    display: block;
    margin-left: 0.5rem;
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

@include media-netbook {
  .header--top {
    &__container {
      padding-left: 0.625rem;
      padding-right: 0.625rem;
    }

    &__logo {
      width: 6.5rem;
      // height: 1.5625rem;
    }

    &__nav {
      width: calc(100% - 15rem);
      height: 1.25rem;
      overflow: hidden;

      &--link {
        font-size: 0.75rem;
        line-height: 1.25rem;
        white-space: nowrap;
      }
    }

    &-wrapper {
      height: 2rem;
      overflow: hidden;
      overflow-x: scroll;
    }

    &__personal-score-container {
      display: none;
    }

    &__personal-avatar {
      margin-left: 0.5rem;
      width: 1.875rem;
      height: 1.875rem;
    }

    &__notify-panel {
      right: 1rem;
    }
  }
}

@include media-mobile {
  .header--top {
    &__nav {
      margin-left: 0.75rem;
      width: calc(100% - 12rem);
      height: 1.25rem;
      overflow: hidden;

      &-wrapper {
        height: 2rem;
        overflow: hidden;
        overflow-x: scroll;
      }

      &--link {
        white-space: nowrap;

        &:last-child {
          margin-right: 0.5rem;
        }
      }
    }

    &__personal-avatar {
      margin-left: 0.5rem;
    }
  }

  .header--top__nav > * + * {
    margin-left: 0.75rem;
  }

  // .header--top__nav--link--desktop {
  //   display: none;
  // }
}

.authorization {
  flex-grow: 1;
  width: 100%;
  padding: 2.8125rem 0;
  overflow: hidden;

  // &:after {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 50%;
  //   bottom: 0;
  //   transform: translateX(-50%);
  //   width: 80rem;
  //   background: url("/assets/images/decor-auth-desktop@2x-min.png") top
  //     4.5625rem right -1px / 38.25rem auto no-repeat;
  //   z-index: 1;
  // }

  &__wrapper {
    width: 29.5rem;
  }

  &__nav {
    display: flex;
    align-items: flex-end;
    margin-top: 1.25rem;
    padding: 0 0.25rem;
    border-bottom: 1px solid $color-grey;

    &--link {
      position: relative;
      padding: 0.875rem;
      font-family: $font-main;
      font-weight: 400;
      font-size: 0.9375rem;
      line-height: 1.25rem;
      letter-spacing: -0.24px;
      color: $color-grey-dark;
      text-decoration: none;
      transition: color $transition;

      &:after {
        content: "";
        position: absolute;
        right: 0.875rem;
        left: 0.875rem;
        bottom: 0;
        display: block;
        border-bottom: 2px solid $color-green;
        opacity: 0;
        transition: opacity $transition;
      }

      &:hover {
        color: $color-green;
        text-decoration: none;

        &:after {
          opacity: 0.5;
        }
      }

      &.active {
        font-weight: 500;
        color: $color-black;
        text-decoration: none;

        &:after {
          opacity: 1;
        }
      }
    }
  }
}

@include media-netbook {
  .authorization {
    flex-grow: 1;
    min-height: auto;
    background-size: 80rem auto;
  }
}

@include media-tablet {
  .authorization {
    &:after {
      width: 64rem;
      background-position: top 4.5625rem right -3rem;
      background-size: 30rem auto;
    }
  }
}

@include media-mobile {
  .authorization {
    padding: 3rem 0 17.5rem;

    // &:after {
    //   width: 48rem;
    //   background-image: url("../../assets/images/decor-auth-mobile@2x-min.png");
    //   background-size: 43.375rem auto;
    //   background-position: left 4.625rem bottom -15.4375rem;
    // }

    &_reg {
      padding-bottom: 3rem;

      // &:after {
      //   background-image: url("../../assets/images/decor-auth-reg-mobile@2x-min.png");
      //   background-size: 15.6875rem auto;
      //   background-position: left 18.75rem top 21.375rem;
      // }
    }

    &__wrapper {
      width: 100%;
    }

    &__headline {
      position: relative;
      max-width: 28.5rem;

      // &:after {
      //   content: "";
      //   position: absolute;
      //   top: -3rem;
      //   right: 1.0625rem;
      //   display: block;
      //   width: 4.8125rem;
      //   height: 4.25rem;
      //   background: url("../../assets/images/headline-decor@2x-min.png")
      //     center/100% no-repeat;
      // }
    }
  }
}

@include media-mobile-small {
  .authorization {
    width: 100%;
    min-height: auto;
    background-position: center bottom -7.75rem;
    background-size: 100% auto;
    overflow: hidden;

    &_reg {
      background-position: center bottom -7.5rem;
    }

    &__wrapper {
      width: 22.1875rem;
    }

    &__nav {
      margin-top: 1.5rem;
      padding: 0 0.125rem;
    }

    .container {
      padding-left: 0.625rem;
      padding-right: 0.625rem;
    }

    .form {
      .row {
        margin-left: -0.625rem;
        margin-right: -0.625rem;

        > [class*="col"] {
          padding-left: 0.625rem;
          padding-right: 0.625rem;
        }
      }
    }
  }
}

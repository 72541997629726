.react-datepicker {
  border-radius: 1.25rem;
  overflow: hidden;
  border: 0;

  &__portal {
    opacity: 1;
    background-color: rgba(64, 64, 64, 0.3);
    backdrop-filter: blur(5px);
  }

  &__header {
    padding-top: 0.75rem;
    padding-bottom: 0.5rem;
    position: static;
    background-color: #f8f8f8;
    border-bottom: 1px solid $color-grey-dark;
  }

  &__header__dropdown {
    margin-top: 0.5rem;
  }

  &__portal &__day-name {
    height: 2rem;
    line-height: 2rem;
    font-size: 0.9375rem;
  }

  &__portal &__day {
    height: 3rem;
    font-size: 0.9375rem;
    line-height: 3rem;
  }

  &__day--keyboard-selected:hover {
    background-color: $color-green;
  }

  &__day--outside-month {
    color: grey;
  }

  &__navigation {
    top: 0.75rem;
  }

  &__portal &__current-month {
    font-family: $font-main;
    font-size: 1.25rem;
    font-weight: 500;
    color: $color-black;
  }

  &__current-month {
    text-transform: capitalize;
    &:last-child {
      display: none;
    }
  }

  &__year-read-view--selected-year {
    font-size: 1rem;
  }

  &__year-read-view--down-arrow {
    right: -1rem;
    top: 0.25rem;
  }

  &__year-dropdown {
    flex-direction: column;
    display: flex;
    background-color: #ffffff;
    width: 100%;
    left: 0;
    top: 3.125rem;
    border-radius: 0;
    border: 0;
    height: calc(100% - 3rem);
  }

  &__year-option {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9375rem;
    line-height: 1;

    &:hover {
      background-color: $color-green;
      color: $color-white;
    }

    &:first-of-type,
    &:last-of-type {
      border-radius: 0;

      &:hover {
        background-color: $color-white;
      }
    }

    &--selected_year,
    &--selected {
      display: none;
    }
  }

  &__navigation--years {
    position: relative;
    top: initial;
    width: 2.5rem;
    height: 1.25rem;
  }

  &__navigation--years-upcoming {
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0.25rem;
      left: calc(50% - 0.625rem);
      width: 1.25rem;
      height: 1.25rem;
      border-top: 0.25rem solid $color-green;
      border-right: 0.25rem solid $color-green;
      transform-origin: center center;
      transform: rotate(-45deg);
    }
  }

  &__navigation--years-previous {
    &:before {
      content: "";
      display: block;
      position: absolute;
      bottom: 0.25rem;
      left: calc(50% - 0.625rem);
      width: 1.25rem;
      height: 1.25rem;
      border-bottom: 0.25rem solid $color-green;
      border-right: 0.25rem solid $color-green;
      transform-origin: center center;
      transform: rotate(45deg);
    }
  }

  &__day--keyboard-selected,
  &__day--selected {
    background-color: $color-green;
  }
}

$color-white: #ffffff;
$color-black: #000000;
$color-green: #00b956;
$color-green-light: #d9f5e6;
$color-green-chat: #ccf1dd;
$color-purple: #731982;
$color-grey: #d8d8d8;
$color-grey-dark: #808080;
$color-grey-light: #ededed;
$color-grey-background: #f8f8f8;

$color-red: #f62434;
$color-blue: #3158b3;

$green-card-gradient: linear-gradient(270deg, #16995d 0%, #06b158 100%);
$green-card-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
$white-card-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);

$transition: 0.3s ease;

// FONTS
$font-main: "MegaFon Graphik LC", "Arial", "Helvetica", sans-serif;

.profile-wishes {
  flex-grow: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    z-index: 0;
  }

  // &--wishes {
  //   &:after {
  //     background-image: url("../../../assets/images/image-profile-wishes@2x.png");
  //     background-size: 18.25rem auto;
  //     background-position: 0 19.75rem;
  //   }
  // }

  // &--porforms {
  //   &:after {
  //     background-image: url("../../../assets/images/image-profile-perform@2x.png");
  //     background-size: 23.125rem auto;
  //     background-position: 0 24.5625rem;
  //   }
  // }

  &__wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 64.25rem;
    padding-top: 3.8125rem;
    padding-bottom: 12.5rem;
    z-index: 1;
  }

  &__menu-back {
    width: 100%;
    padding-bottom: 0.625rem;
  }

  &__contaiter {
    margin-left: auto;
    width: 43.5rem;
  }

  &__filter {
    max-width: 20.625rem;
  }

  &__tile-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    // margin-top: 1.5rem;
  }

  &__has-notify,
  &__has-no-notify {
    margin-bottom: 1rem;
    font-size: 0.9375rem;
    line-height: 1.4;
    font-weight: 500;
  }

  &__has-notify {
    color: #00b956;
  }

  &__has-no-notify {
    color: #808080;
  }

  &__tile-list + &__has-no-notify {
    margin-top: 2rem;
  }
}

.profile-wishes__tile-list + .profile-wishes__has-notify {
  margin-top: 1.5rem;
}

@include media-tablet-wide {
  .profile-wishes {
    &__contaiter {
      margin-left: auto;
    }

    &__wrapper {
      width: 61.25rem;
    }
  }
}

@include media-tablet {
  .profile-wishes {
    &--wishes {
      background-image: none;
    }

    &--porforms {
      background-image: none;
    }

    &__contaiter {
      margin-left: 0;
    }

    &__wrapper {
      flex-direction: column;
      width: 43.625rem;
    }

    &__filter {
      margin-top: 3rem;
    }

    &__has-notify,
    &__has-no-notify {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }

    &__tile-list + &__has-no-notify {
      margin-top: 1.5rem;
    }
  }
}

@include media-mobile {
  .profile-wishes {
    &:after {
      // background-image: url("/assets/images/image-profile-wishes-mobile@2x-min.png");
      background-image: none;
      background-position: center -8rem;
      background-size: 48rem auto;
      background-repeat: repeat-y;
    }

    &__wrapper {
      align-items: center;
      width: 28.65rem;
      padding-bottom: 2.5rem;
    }

    &__contaiter {
      width: 21.25rem;
    }

    &__tile-list {
      grid-template-columns: repeat(1, 1fr);
      gap: 1rem;
      // margin-top: 1rem;
    }
  }
}

@include media-mobile-small {
  .profile-wishes {
    &__wrapper {
      width: 100%;
    }

    &__contaiter {
      width: 22.1875rem;
    }
  }
}

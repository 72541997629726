.footer {
  position: relative;
  width: 100%;
  padding: 0.75rem 0;
  background: $color-white;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 65.125rem;
    padding: 0;
  }

  &__aside {
    flex-shrink: 0;
    width: 12.5rem;
  }

  &__center {
    flex-grow: 1;
    padding: 0 0.75rem;
    padding-right: 0;
  }

  &__copyright {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
    color: $color-grey-dark;
  }

  &__nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &--item {
      display: flex;
      align-items: center;
      margin: 0.125rem 0.75rem;
      padding: 0;
      border: 0;
      background-color: transparent;
      font-weight: 400;
      font-size: 0.9375rem;
      line-height: 1.25rem;
      letter-spacing: -0.24px;
      color: $color-grey-dark;

      &:hover {
        color: $color-green;
        text-decoration: underline;
      }
    }
  }

  &__button-feedback {
    font-weight: 500;
    color: #00b956;
    text-decoration: underline;
  }

  &__telegram-link {
    margin-left: auto;
    width: 1.75rem;
    height: 1.75rem;
    background-image: url("../../assets/images/icon-telegram.svg");
    background-size: contain;
    background-position: 0 0;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }
}

@include media-netbook {
  .footer {
    position: static;
    padding: 1rem 0.625rem;
    z-index: 0;

    &__container {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    &__center {
      width: 100%;
      padding: 0;
    }

    &__nav {
      width: 100%;
      justify-content: flex-start;

      &--item {
        margin: 0;
        margin-right: 1.5rem;
        margin-bottom: 0.5rem;
        font-size: 0.875rem;

        // &:nth-child(1) {
        //   order: 1;
        // }

        // &:nth-child(2) {
        //   order: 2;
        // }

        // &:nth-child(3) {
        //   order: 3;
        // }

        // &:nth-child(4) {
        //   order: 4;
        // }
      }
    }

    &__aside {
      margin-top: 1rem;

      &_mh {
        display: none;
      }
    }

    &__copyright {
      font-size: 0.75rem;
    }

    &__telegram-link {
      margin-left: auto;
    }
  }
}

.form {
  width: 100%;

  &__text {
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: -0.15px;

    b,
    strong {
      font-weight: 500;
    }

    span {
      color: $color-green;
    }

    a,
    button {
      color: $color-green;
      text-decoration: underline;

      &:hover {
        color: $color-green;
        text-decoration: none;
      }
    }
  }
}

@import "./formReg/formReg.scss";
@import "./formProfileUser/formProfileUser.scss";
@import "./formProfileAvatar/formProfileAvatar.scss";
@import "./formWishProfile/formWishProfile.scss";
@import "./formProfileWishesFilter//formProfileWishesFilter.scss";
@import "./formWishListFilter/formWishListFilter.scss";
@import "./formRatingFilter/formRatingFilter.scss";
@import "./formGalleryUpload/formGalleryUpload.scss";
@import "./formWishCreate/formWishCreate.scss";
@import "./formWishDone/formWishDone.scss";

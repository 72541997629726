.swiper--arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  color: $color-grey-dark;
  transition: color $transition;
  cursor: pointer;

  &.swiper-button-disabled {
    opacity: 0.5;
    cursor: default;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  } 
}
.wishlist-filter {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;

  &__search {
    margin-right: 0.5rem;
    width: 22.9375rem;
    width: 100%;
  }

  &__search-field {
    border-radius: 1.5rem;
  }

  &__city-selector {
    flex-shrink: 0;
    position: relative;
    width: 17.625rem;
    z-index: 9;
  }

  &__count-selector {
    flex-shrink: 0;
    margin-left: 0.5rem;
  }

  &__apply-button {
    display: none;
  }

  .button--modal-close {
    display: none;
  }
}

@include media-netbook {
  .wishlist-filter {
    position: fixed;
    top: 8.5rem;
    left: 0;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: calc(100vh - 8.5rem);
    padding-top: 3.25rem;
    padding-right: 0;
    padding-bottom: 3.375rem;
    background-color: $color-white;
    border-radius: 1.25rem 1.25rem 0 0;
    background-image: url("/assets/images/bg-filter-mobi@2x.png"),
      url("/assets/images/bg-filter-2.svg");
    background-size: 13.75rem auto, 100% auto;
    background-position: 50% 11.5rem, 0 0;
    background-repeat: no-repeat, no-repeat;
    box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.1);
    z-index: 20;

    &.filter-with-counter {
      background-image: url("/assets/images/bg-filter-mobi@2x.png"),
        url("/assets/images/bg-filter-2.svg");
      background-size: 13.75rem auto, 100% auto;
      background-position: 50% 15rem, 0 0;
      background-repeat: no-repeat, no-repeat;
    }

    &--show {
      display: flex;
    }

    &__search {
      margin-bottom: 0.875rem;
      margin-right: 0;
      width: 22.1875rem;
    }

    &__search-field {
    }

    &__city-selector {
      width: 22.1875rem;
      margin-bottom: 0.875rem;
    }

    &__count-selector {
      margin-left: 0;
      width: 22.1875rem;
    }

    &__apply-button {
      display: flex;
      margin-top: auto;
    }

    .button--modal-close {
      display: block;
    }
  }
}

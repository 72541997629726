.avatar__image {
  position: relative;
  margin-bottom: 1.875rem;

  img {
    display: block;
    width: 7.5rem;
    height: 7.5rem;
    border-radius: 50%;
  }
}

.avatar__form {
  position: relative;
}

.avatar__label {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 1.875rem;
  height: 1.875rem;
  background-color: $color-blue;
  border-radius: 50%;
  cursor: pointer;

  svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: $color-white;
  }
}

.avatar__input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
}
.articles {
  position: relative;
  width: 100%;
  flex-grow: 1;

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;

    .article-tile {
      margin: 0 1.5rem 2rem 1.5rem;
    }
  }
}

.article {
  margin-bottom: 2.5rem;
  width: 100%;
  padding-top: 2.5rem;
  padding-left: 4.25rem;
  padding-right: 4.25rem;
  padding-bottom: 2.5rem;
  background-color: #fff;
  border-radius: 1rem;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  &__read-link {
    margin-top: 1.875rem;
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    font-weight: 400;
    color: #00b956;

    &:hover {
      color: #00b956;
    }

    svg {
      fill: #00b956;
      display: block;
      height: 1rem;
      width: 1rem;
    }
  }

  &__title {
    margin-bottom: 2.5rem;

    h2 {
      font-size: 2.25rem;
      line-height: 1.2;
      font-weight: 600;
      color: #272727;
    }

    h3 {
      font-size: 1.875rem;
      line-height: 1.2;
      font-weight: 600;
      color: #272727;
    }
  }

  &__body {
    color: #000;

    &--limited {
      position: relative;
      max-height: 3.75rem;
      overflow: hidden;

      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1rem;
        background: linear-gradient(to bottom, rgba(#fff, 0), #fff);
      }
    }

    h2 {
      margin-bottom: 1.875rem !important;
      font-size: 1.5rem !important;
      line-height: 1.4 !important;
      // color: #000 !important;
    }

    h3 {
      margin-bottom: 1.875rem !important;
      font-size: 1.375rem !important;
      line-height: 1.4 !important;
      // color: #000 !important;
    }

    & > p {
      margin-bottom: 1.875rem;
    }

    p {
      font-size: 1.125rem !important;
      line-height: 1.4 !important;
      // color: #000 !important;
    }

    a {
      font-size: 1.125rem !important;
      line-height: 1.4 !important;
      color: #00b956 !important;
      text-decoration: underline;
    }

    blockquote {
      position: relative;
      margin-bottom: 1.875rem;
      padding-left: 1.25rem;
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;
      background-color: #00b9561f;

      * {
        font-style: italic !important;
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 100%;
        background-color: #00b956;
      }
    }

    img {
      display: block !important;
      margin-bottom: 1.875rem !important;
      width: 100% !important;
      height: auto !important;
      border-radius: 1rem;

      &.aligncenter {
        margin-left: auto !important;
        margin-right: auto !important;
      }

      &.width-50-percent {
        width: 50% !important;
      }
    }

    ul {
      margin-bottom: 1.875rem !important;
      padding-left: 1.25rem;
      list-style-type: disc;

      li {
        position: relative;
        font-size: 1.125rem !important;
        line-height: 1.4 !important;
        list-style-type: disc;

        // &::before {
        //   content: "";
        //   display: block;
        //   position: absolute;
        //   top: 0.5rem;
        //   left: -1rem;
        //   width: 0.3125rem;
        //   height: 0.3125rem;
        //   border-radius: 50%;
        //   background-color: #000 !important;
        // }
      }
    }

    ol {
      margin-bottom: 1.875rem !important;
      padding-left: 1.25rem;
      list-style-type: decimal;

      li {
        position: relative;
        font-size: 1.125rem !important;
        line-height: 1.4 !important;
        list-style-type: decimal;
      }
    }

    strong {
      font-weight: 600 !important;
    }
  }

  &__read-blog-button {
  }
}

@media screen and (max-width: 768px) {
  .articles {
    position: relative;
    width: 100%;
    flex-grow: 1;

    &__container {
      padding-top: 1.875rem;
      padding-bottom: 1.875rem;

      .article-tile {
        margin: 0 0 2rem 0;
      }
    }
  }

  //
  .article {
    margin-bottom: 1.875rem;
    width: 100%;
    padding-top: 1.25rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-bottom: 1.25rem;
    background-color: #fff;
    border-radius: 1rem;

    &__container {
      padding-top: 1.875rem;
      padding-bottom: 1.875rem;
    }

    &__title {
      margin-bottom: 1.875rem;

      h2 {
        font-size: 1.375rem;
        line-height: 1.2;
        font-weight: 600;
        color: #272727;
      }

      h3 {
        font-size: 1.25rem;
        line-height: 1.2;
        font-weight: 600;
        color: #272727;
      }
    }

    &__body {
      h2 {
        margin-bottom: 1.25rem !important;
        font-size: 1.5rem !important;
        line-height: 1.4 !important;
        color: #000 !important;
      }

      h3 {
        margin-bottom: 1.25rem !important;
        font-size: 1.375rem !important;
        line-height: 1.4 !important;
        color: #000 !important;
      }

      & > p {
        margin-bottom: 1.875rem !important;
      }

      p {
        font-size: 1rem !important;
        line-height: 1.4 !important;
        color: #000 !important;
      }

      blockquote {
        position: relative;
        margin-bottom: 1.875rem;
        padding-left: 1.25rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        background-color: #00b9561f;

        * {
          font-style: italic !important;
        }

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 2px;
          height: 100%;
          background-color: #00b956;
        }
      }

      img {
        display: block !important;
        margin-bottom: 1.875rem !important;
        width: 100% !important;
        height: auto !important;
        border-radius: 0.625rem;

        &.width-50-percent {
          width: 100% !important;
        }
      }

      ul {
        margin-bottom: 1.875rem !important;
        padding-left: 1.25rem;

        li {
          position: relative;
          font-size: 1rem !important;
          line-height: 1.4 !important;
          color: #000 !important;

          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0.5rem;
            left: -0.875rem;
            width: 0.3125rem;
            height: 0.3125rem;
            border-radius: 50%;
            background-color: #000 !important;
          }
        }
      }

      ol {
        margin-bottom: 1.875rem !important;
        padding-left: 1.25rem;
        list-style-type: decimal;

        li {
          position: relative;
          font-size: 1rem !important;
          line-height: 1.4 !important;
          color: #000 !important;
          list-style-type: decimal;
        }
      }

      strong {
        font-weight: 600 !important;
      }
    }
  }
}

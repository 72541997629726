.modal-body.modal-body--accept {
  padding-left: 24px;
  padding-right: 24px;
}

.modal-accept {
  &__slider {
    width: 26.25rem;
  }

  &__slide {
    width: 26.25rem;
  }

  &__contracts {
    margin-top: 1.125rem;
    width: 100%;
    padding: 0 0.625rem;
  }

  &__contract {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 100%;
  }

  &__contract-data {
    display: flex;
    align-items: center;
  }

  &__contract-ava {
    flex-shrink: 0;
    display: block;
    width: 2.125rem;
    height: 2.125rem;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
    }
  }

  &__contract-name {
    margin-left: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    color: $color-black;
    overflow: hidden;
  }

  &__contract-btn {
    // margin-left: auto;
    margin-top: 1.5rem;
    width: 9.375rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  &__preview {
    margin: 0 auto;
    margin-top: 1.375rem;
    width: 25rem;
    height: 14.0625rem;
    background-color: #d9d9d9;
    border-radius: 0.625rem;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center center;
    }
  }

  &__arrow--prev,
  &__arrow--next {
    position: absolute;
    top: 15rem;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;
    border: 0;
    border-radius: 50%;
    background-color: #fff;
    color: #fff;
    fill: $color-green;

    &:disabled {
      opacity: 0.3;
    }

    svg {
      display: block;
      width: 100% !important;
      height: 100% !important;
      fill: $color-green;
    }
  }

  &__arrow--prev {
    left: 3rem;
  }

  &__arrow--next {
    right: 3rem;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    button {
      margin-left: 0.625rem;
      margin-right: 0.625rem;
    }
  }
}

@include media-mobile {
  .modal-accept {
    &__slider {
      width: 18rem;
    }

    &__slide {
      width: 18rem;
    }

    &__preview {
      margin: 0 auto;
      margin-top: 1.375rem;
      width: 16.75rem;
      height: 14.0625rem;
      background-color: #d9d9d9;
      border-radius: 0.625rem;
    }

    &__arrow--prev,
    &__arrow--next {
      display: none;
    }
  }
}

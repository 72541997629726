@font-face {
  font-family: 'MegaFon Graphik LC';
  src: url('../../assets/fonts/MegaFonGraphikLC-Medium.woff2') format('woff2'),
      url('../../assets/fonts/MegaFonGraphikLC-Medium.woff') format('woff'),
      url('../../assets/fonts/MegaFonGraphikLC-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MegaFon Graphik LC';
  src: url('../../assets/fonts/MegaFonGraphikLC-Semibold.woff2') format('woff2'),
      url('../../assets/fonts/MegaFonGraphikLC-Semibold.woff') format('woff'),
      url('../../assets/fonts/MegaFonGraphikLC-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MegaFon Graphik LC';
  src: url('../../assets/fonts/MegaFonGraphikLC-Black.woff2') format('woff2'),
      url('../../assets/fonts/MegaFonGraphikLC-Black.woff') format('woff'),
      url('../../assets/fonts/MegaFonGraphikLC-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MegaFon Graphik LC';
  src: url('../../assets/fonts/MegaFonGraphikLC-Regular.woff2') format('woff2'),
      url('../../assets/fonts/MegaFonGraphikLC-Regular.woff') format('woff'),
      url('../../assets/fonts/MegaFonGraphikLC-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MegaFon Graphik LC';
  src: url('../../assets/fonts/MegaFonGraphikLC-Bold.woff2') format('woff2'),
      url('../../assets/fonts/MegaFonGraphikLC-Bold.woff') format('woff'),
      url('../../assets/fonts/MegaFonGraphikLC-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MegaFon Graphik LC';
  src: url('../../assets/fonts/MegaFonGraphikLC-Light.woff2') format('woff2'),
      url('../../assets/fonts/MegaFonGraphikLC-Light.woff') format('woff'),
      url('../../assets/fonts/MegaFonGraphikLC-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}


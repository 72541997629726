.chat-profile {
  flex-grow: 1;
  margin-top: 2rem;
  width: 28.5rem;
  height: 22.3125rem;
  background-color: $color-white;
  border-radius: 1.25rem;
  overflow: hidden;
}

@include media-tablet {
  .chat-profile {
    height: 26.8125rem;
    width: 100%;
  }
}

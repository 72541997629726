.wishlist-paginator {
  &__title {
    width: 100%;
    margin-bottom: 2.625rem;
  }

  &__tile-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem 1.5rem;
  }

  &__page-list {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.875rem;
  }

  &__button-prev,
  &__button-next,
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.125rem;
    width: 2rem;
    height: 2rem;
  }

  &__button-prev,
  &__button-next {
    svg {
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      fill: $color-grey-dark;
      transition: fill 0.3s;
    }

    &:hover {
      svg {
        fill: $color-green;
      }
    }

    &:disabled {
      svg {
        opacity: 0.5;
        fill: $color-grey-dark !important;
      }
    }
  }

  &__button {
    padding-top: 0.1875rem;
    border-radius: 50%;
    font-size: 0.875rem;
    line-height: 1;
    color: $color-black;
    transition: color 0.3s;

    &:hover {
      color: $color-green;
    }

    &--active {
      background-color: $color-green;
      color: $color-white !important;
    }
  }
}

@include media-netbook {
  .wishlist-paginator {
    &__title {
      margin-bottom: 1rem;
    }
    &__tile-list {
      grid-template-columns: repeat(2, 1fr);
      gap: 1.5rem;
    }
    &__page-list {
      margin-top: 0.75rem;
    }
  }
}

@include media-mobile-small {
  .wishlist-paginator {
    &__tile-list {
      grid-template-columns: 1fr;
      padding: 0.5rem 0.625rem 0;
    }
  }
}

.swiper--dots {
  display: flex;
  align-items: center;
  justify-content: center;

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 0.25rem;
    opacity: 1;
    cursor: pointer;

    &:after {
      content: "";
      display: block;
      width: 0.375rem;
      height: 0.375rem;
      background: $color-green;
      border: 2px solid $color-green;
      border-radius: 50%;
      transition: background-color $transition;
    }

    &:hover {
      &:after {
        background: rgba(255, 255, 255, 0);
      }
    }

    &.active {
      &:after {
        width: 0.875rem;
        height: 0.875rem;
        background: rgba(255, 255, 255, 0);
      }
    }
  }
}

@include media-mobile {
  .swiper--dots {
  
    &__item {
      margin: 0 0.1563rem;
    }
  }
}
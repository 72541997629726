.form-select {
  &__control {
    min-height: 3rem !important;
    box-shadow: 0 !important;
    border-radius: 0.3125rem !important;

    &--is-focused {
      border-color: #00b956 !important;
      box-shadow: none !important;
    }

    &--menu-is-open {
      border-radius: 0.3125rem 0.3125rem 0 0 !important;
      border-radius: 0.3125rem !important;

      .form-select__indicator {
        transform: rotate(180deg);
      }
    }
  }

  &__multi-value {
    border-radius: 0.9375rem !important;
    background-color: #ededed !important;
  }

  // &__multi-value__label {
  // }
}

.form-select-hash-container .form-select__multi-value__label {
  &:before {
    content: "#";
  }
}

.form-select__clear-indicator {
  display: none !important;
}

.form-select__value-container {
  padding: 0 0.625rem 0 0.9375rem !important;
}

.form-select__single-value,
.form-select__placeholder {
  margin: 0 !important;
  font-family: $font-main !important;
  font-weight: 400 !important;
  font-size: 0.9375rem !important;
  line-height: 1.25rem !important;
  white-space: nowrap;
}

.form-select__single-value {
  color: $color-black;
}

.form-select__input-container {
  margin: 0 !important;
}

.form-select__placeholder {
  color: $color-grey-dark !important;
  overflow: hidden;
}

.form-select__indicator-separator {
  display: none;
}

.form-select__menu-notice {
  padding: 0 !important;
  font-size: 0.9375rem;
}

.form-select__indicators {
  padding-right: 0.9375rem;
}

.form-select__indicator {
  width: 1rem !important;
  height: 1rem !important;
  padding: 0 !important;

  svg {
    width: 100% !important;
    height: 100% !important;
    fill: $color-grey-dark;
  }
}

.form-select__menu {
  position: relative;
  margin: 0 !important;
  height: 7.5rem !important;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  border-radius: 0 !important;
  border: 1px solid transparent !important;
  border-bottom-left-radius: 0.9375rem !important;
  border-bottom-right-radius: 0.9375rem !important;
  border-top: 0 !important;
  border: 0 !important;
  box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.1) !important;
  z-index: 2 !important;

  &.autoheight {
    height: auto !important;
    max-height: auto !important;
  }
}

.form-select__menu-list {
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  max-height: none !important;
}

.form-select__option {
  padding: 0.5rem 0 !important;
  background-color: $color-white !important;
  font-family: $font-main !important;
  font-weight: 400 !important;
  font-size: 0.9375rem !important;
  color: $color-black !important;
  cursor: pointer !important;
  transition: color 0.3s;

  &:nth-child(n + 2) {
    border-top: 1px solid $color-grey-light;
  }

  &--is-selected {
    background-color: $color-white !important;
  }

  &:hover {
    color: $color-green !important;
  }
}

.wishlist-slider {
  &__title {
    width: 100%;
    margin-bottom: 2.625rem;
  }

  &__list {
    overflow: visible !important;
  }

  &__navigation {
    margin-top: 2.6875rem;
  }

  &__arrow--prev {
    order: 1;
  }

  &__arrow--next {
    order: 3;
  }

  &__dots {
    order: 2;
  }
}

.wishlist-slider + .wishlist-slider {
  margin-top: 2.0625rem;

  &:before {
    content: "";
    display: block;
    margin-bottom: 3.125rem;
    width: 100%;
    height: 1px;
    background-color: $color-grey;
  }
}

@include media-netbook {
  .wishlist-slider {
    &__title {
      margin-bottom: 1rem;
    }

    &__navigation {
      margin-top: 1.625rem;
    }
  }

  .wishlist-slider + .wishlist-slider {
    margin-top: 1.625rem;

    &:before {
      margin-bottom: 1rem;
    }
  }
}

@include media-mobile {
  .wishlist-slider {
    &__navigation {
      justify-content: flex-end;
      padding-right: 1rem;
    }

    &__title {
      margin-bottom: 1.5rem;
    }
  }

  .wishlist-slider + .wishlist-slider {
    margin-top: 1.75rem;

    &:before {
      margin-bottom: 1.375rem;
      background-color: $color-white;
    }
  }
}

.react-photo-album--photo {
  background-color: $color-grey;
}

.lazy-load-image-background {
  img {
    // transition: 0.3s;
    opacity: 0;
  }
}

.lazy-load-image-loaded {
  img {
    // transition: 0.3s;
    opacity: 1;
  }
}

.gallery {
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: $color-grey-background;

  &__wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 66rem;
    padding-top: 2.125rem;
    padding-bottom: 2.125rem;
  }

  &__container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-top: 0.625rem;
    width: 100%;
    border-radius: 1.25rem;
    padding: 1.5rem;
    background-color: $color-white;
  }

  // HEADER
  &__header {
    display: flex;
    align-items: center;
    padding-bottom: 1.125rem;
  }

  &__header-icon {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: $color-green-light;

    svg {
      display: block;
      width: 1.75rem;
      height: 1.75rem;
    }
  }

  &__header-content {
    padding-left: 1rem;
  }

  &__header-title {
    font-size: 1.5rem;
    line-height: 1;
    font-weight: 500;
    font-style: normal;
  }

  &__header-subtitle {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $color-grey-dark;

    span {
      color: $color-black;
    }
  }

  &__header-button {
    margin-left: auto;
  }

  // BODY
  &__body {
    border-top: $color-grey-dark;
    padding-top: 1.5625rem;
  }

  &__images {
  }

  &__images--profile {
    margin-top: 1.25rem;
  }

  &__image-wrapper {
    position: relative;
    background-color: $color-grey;

    span {
      display: block !important;
    }
  }

  &__image {
    width: 100% !important;
  }

  &__remove-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0.7;

    &:hover {
      svg {
        fill: $color-green;
      }
    }

    svg {
      display: block;
      width: 0.875rem;
      height: 0.875rem;
      transition: fill 0.25s;
      fill: rgba(0, 0, 0, 0.61);
    }
  }

  // FOOTER
  &__footer {
    display: flex;
    justify-content: center;
    padding-top: 1.875rem;
  }
}

@media screen and (max-width: 1080px) {
  .gallery {
    &__wrapper {
      width: 100%;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

@include media-mobile-medium {
  .gallery {
    &__container {
      padding: 1.5rem 1rem;
    }

    &__header {
      position: relative;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 5rem;
    }

    &__header-icon {
      position: absolute;
      top: 0;
      left: 0;
    }

    &__header-content {
      padding-top: 0.5rem;
      padding-left: 0;
    }

    &__header-button {
      margin-top: 1rem;
      margin-left: 0;
    }
  }
}

.form-wish-create__textarea {
  min-height: 5rem !important;
}

.richtext__wrapper {
  min-height: 5rem;
  padding: 0.8125rem 0.4375rem 0.8125rem 0.9375rem;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 0.3125rem;
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.25rem;
  letter-spacing: -0.24px;
  color: #000000;

  &.disabled {
    pointer-events: none;
    background: #f2f2f2;
    border-color: #e6e6e6;
    color: #999;
  }

  &.preview {
    pointer-events: none;
  }
}

.chat-body {
  position: relative;
  flex-grow: 1;
  height: 10rem;
  padding: 0.3125rem 0 0.3125rem 0.625rem;

  &__spiner {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: $color-white;

    span {
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      margin: 0.25rem;
      background-color: $color-green;
      transform-origin: center;

      &:nth-child(1) {
        animation: loader-spin-dot 2s infinite linear;
        animation-delay: 0;
      }

      &:nth-child(2) {
        animation: loader-spin-dot 2s infinite linear;
        animation-delay: 0.7s;
      }

      &:nth-child(3) {
        animation: loader-spin-dot 2s infinite linear;
        animation-delay: 1.4s;
      }
    }
  }

  @keyframes loader-spin-dot {
    0% {
      transform: scale(1);
    }
    5% {
      transform: scale(1.5);
    }
    33% {
      transform: scale(1.5);
    }
    38% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }

  &__plug {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    img {
      margin-bottom: 0.5rem;
      display: block;
      width: 4rem;
      height: 3.5625rem;
    }

    p {
      width: 100%;
      max-width: 16.25rem;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 500;
      color: $color-grey-dark;
      text-align: center;
    }
  }

  &__message {
    display: flex;
    align-items: flex-start;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    padding-right: 0.75rem;

    &--my {
      margin-left: auto;
      width: 18.125rem;
    }

    &--user {
      width: 100%;
    }

    &--show-origin {
      .chat-body__message-inner {
        border-color: #00b956 !important;
        transition: border-color 0.1s;
      }
    }
  }

  &__divider {
    margin-bottom: 0.9375rem;
    width: calc(100% - 0.625rem);
    padding: 0.25rem;
    text-align: center;
    background-color: #d8d8d8;
    font-size: 0.875rem;
    line-height: 1.2;
    font-weight: 400;
    color: #808080;
  }

  &__avatar {
    flex-shrink: 0;
    display: block;
    margin-right: 0.375rem;
    width: 2.125rem;
    height: 2.125rem;
    border-radius: 50%;
    overflow: hidden;
  }

  &__message-data {
    // flex-grow: 1;
    min-height: 2.125rem;
    width: 100%;

    &--my {
      .chat-body__message-inner {
        background-color: $color-grey;
      }
    }

    &--user {
      width: 17.5rem;
      .chat-body__message-inner {
        background-color: $color-green-chat;
      }
    }
  }

  &__message-inner {
    width: 100%;
    border-radius: 1rem;
    padding: 0.5rem;
    border: 2px solid transparent;
    transition: border-color 3s;
  }

  &__quote {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
    width: 100%;
    padding: 0.25rem;
    background-color: #fff6;
    border-left: 0.25rem solid #00b956;
    border-radius: 0.25rem;
    text-align: left;
    cursor: pointer;

    &-name {
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1.5;
      color: #808080;
    }

    &-message {
      width: 100%;
      font-size: 0.75rem;
      line-height: 1.5;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__message-meta {
    display: flex;
    padding-left: 0.125rem;
    padding-right: 0.4375rem;
  }

  &__user-name {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.25rem;
    font-size: 0.625rem;
    line-height: 1;
    font-weight: 500;
    color: $color-grey-dark;
  }

  &__user-text {
    width: 100%;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $color-black;
    overflow: hidden;
    text-overflow: ellipsis;

    .wide-image {
      display: block;
      width: 100%;
      height: auto;
    }

    .smile-image {
      display: block;
      width: 2.125rem;
      height: 2.125rem;
    }

    .file-icon {
      display: block;
      width: 1.6875rem;
      height: 2.125rem;
    }

    .file-name {
      display: block;
      margin-top: 0.5rem;
      font-size: 0.875rem;
      line-height: 1;
    }

    .file-link {
      margin-top: 0.5rem;
      border: 0;
      background-color: transparent;
      font-size: 0.875rem;
      line-height: 1;
      color: $color-green;
      text-decoration: underline;
    }
  }

  &__message-date,
  &__message-status {
    font-size: 0.5625rem;
    line-height: 1.25rem;
  }

  &__message-status {
    margin-left: auto;
  }
}

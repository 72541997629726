.form-select-filter-container {
  // max-width: 17.625rem;

  .form-select__control {
    height: 3rem !important;
    border-radius: 1.5rem !important;

    &--menu-is-open {
      border-radius: 1.5rem 1.5rem 0 0 !important;
    }
  }
}

.rating {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 40rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: $color-grey-background;
  overflow: hidden;

  // &:after {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   bottom: 0;
  //   right: 0;
  //   background: url("/assets/images/bg-rating-left@2x.png"),
  //     url("/assets/images/bg-rating-right@2x.png");
  //   background-size: 25.4375rem auto, 25.4375rem auto;
  //   background-position: 0 14.625rem, calc(100% + 2rem) 14.625rem;
  //   background-repeat: no-repeat, no-repeat;
  //   // background-attachment: fixed;
  //   z-index: 1;
  // }

  &__wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 37rem;
    margin: 0 auto;
  }

  &__container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__settings {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 0.75rem;
  }

  &__total {
    flex-shrink: 0;
    min-width: auto;
    height: 3rem;
    padding: 0.75rem 1rem;

    .total-counter__title {
      font-size: 0.9375rem;
      line-height: 1.2;
      font-weight: 400;
      color: #808080;
    }

    .total-counter__quantity {
      font-size: 1.125rem;
      line-height: 1;
      color: #272727;
    }
  }

  &__list {
    margin-bottom: auto;
  }

  &__column-name {
    width: calc(12.5625rem - 1px);
    margin-left: auto;
    margin-bottom: 1px;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    color: $color-black;
    text-align: center;
  }
}

.member {
  display: flex;
  margin-bottom: 1.25rem;
  color: $color-black;

  &__user {
    flex-grow: 1;
    display: flex;
    align-items: center;
    width: 24.375rem;
    height: 5rem;
    padding: 0.75rem 1.25rem;
    border-top-left-radius: 1.25rem;
    border-bottom-left-radius: 1.25rem;
    background-color: $color-white;
  }

  &__avatar {
    flex-shrink: 0;
    position: relative;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
      overflow: hidden;
    }

    // &:after {
    //   content: "";
    //   display: block;
    //   position: absolute;
    //   bottom: 0.25rem;
    //   right: 0.25rem;
    //   width: 0.625rem;
    //   height: 0.625rem;
    //   border: 2px solid $color-white;
    //   border-radius: 50%;
    //   background-color: $color-green;
    // }
  }

  &__name {
    margin-left: 1.375rem;
    max-width: calc(100% - 3.5rem - 1.375rem);
    font-size: 0.9375rem;
    line-height: 1.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $color-black;

    &:hover {
      color: $color-black;
      text-decoration: underline;
    }
  }

  &__points {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 1px;
    width: calc(12.5625rem - 1px);
    height: 5rem;
    background-color: $color-white;
    border-top-right-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1;
  }
}

@include media-netbook {
  .rating {
    .container {
      padding-left: 0.625rem;
      padding-right: 0.625rem;
    }

    &__settings {
      align-items: center;

      .button-mobile-menu {
        margin-bottom: 0;
      }
    }
  }
}

@include media-tablet {
  .rating {
    // &:after {
    //   background-image: url("/assets/images/bg-rating-mob@2x.png");
    //   background-size: 23.4375rem auto;
    //   background-position: 0 100%;
    //   background-attachment: unset;
    // }

    // &:after {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   bottom: 0;
    //   right: 0;
    //   background: url("/assets/images/bg-rating-right@2x.png"),
    //     url("/assets/images/bg-rating-left@2x.png");
    //   background-size: 15.625rem auto, 15.625rem auto;
    //   background-position: 0 calc(100% - 5rem),
    //     calc(100% + 2rem) calc(100% - 5rem);
    //   background-repeat: no-repeat, no-repeat;
    //   // background-attachment: fixed;
    //   z-index: 1;
    // }

    &__wrapper {
      // width: 25.75rem;
      padding-bottom: 18.125rem;
    }

    &__column-name {
      width: calc(6.875rem - 1px);
      font-size: 0.625rem;
      color: $color-grey-dark;
    }
  }

  // MEMBER
  .member {
    margin-bottom: 0.5rem;

    &__user {
      width: 18.75rem;
      height: 3rem;
      padding: 0.5rem;
    }

    &__avatar {
      position: relative;
      width: 2rem;
      height: 2rem;

      // &:after {
      //   content: "";
      //   display: block;
      //   position: absolute;
      //   bottom: 0.25rem;
      //   right: 0.25rem;
      //   width: 0.625rem;
      //   height: 0.625rem;
      //   border: 2px solid $color-white;
      //   border-radius: 50%;
      //   background-color: $color-green;
      // }
    }

    &__name {
      margin-left: 0.75rem;
      max-width: calc(100% - 2rem - 0.75rem);
    }

    &__points {
      width: calc(6.875rem - 1px);
      height: 3rem;
      font-size: 1.125rem;
    }
  }
}

@include media-mobile {
  .rating {
    &__wrapper {
      width: 100%;
    }

    &__settings {
      margin-bottom: 0.5rem;
    }

    &__column-name {
      margin-bottom: 0.5rem;
      width: calc(6.0625rem - 1px);
    }
  }

  // MEMBER
  .member {
    &__user {
      width: 16.0625rem;
    }

    &__points {
      width: calc(6.0625rem - 1px);
    }
  }
}

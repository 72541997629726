.faq {
  position: relative;
  padding-top: 3.75rem;
  padding-bottom: 5.5rem;
  background-image: url("../../assets/images/decor_intro_04.png");
  background-size: 25rem auto;
  background-position: 100% -5rem;
  background-repeat: no-repeat;
  z-index: 1;

  &__headline {
    margin-bottom: 2.625rem;
  }

  &__accordion {
  }
}

@include media-netbook {
  .faq {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

@include media-tablet {
  .faq {
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-size: 20rem auto;
    background-position: 100% -4rem;
  }
}

@include media-mobile {
  .faq {
    padding: 3.125rem 0 3.75rem;
    background-image: none;

    .container {
      padding-left: 0.625rem;
      padding-right: 0.625rem;
    }

    &__headline {
      margin-bottom: 1.625rem;
    }

    &__accordion {
    }
  }
}

@include media-mobile-small {
}

.text--link {
  position: relative;
  display: inline-flex;
  align-items: center;
  // min-height: 2rem;
  // margin-top: auto;
  padding: 0.375rem 0;
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.25rem;
  color: $color-black;
  text-decoration: none;
  z-index: 1;

  &_w500 {
    font-weight: 500;
  }

  svg {
    position: relative;
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
    transition: transform $transition;
  }

  &:hover {
    color: $color-black;

    span {
      text-decoration: underline;
    }

    svg {
      transform: translateX(0.25rem);
    }
  }
}
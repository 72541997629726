.button-action-forward {
  display: flex;
  align-items: center;
  padding: 0;
  border: 0;
  background-color: transparent;

  span {
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 1.25rem;
    color: $color-green;
  }

  svg {
    display: block;
    width: 1rem;
    height: 1rem;
    fill: $color-green;
  }
}

.button-action-logout {
  margin-left: auto;

  span {
    color: #272727;
  }

  svg {
    fill: #272727;
  }
}

@include media-netbook {
  .button-action-logout {
    span {
      color: #272727;
      font-size: 0.75rem;
    }

    svg {
      fill: #272727;
    }
  }
}

@include media-tablet {
}

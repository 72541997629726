.button--text {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: auto;
  max-width: none;
  min-height: auto;
  padding: 0;
  background: transparent;
  box-shadow: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
  font-family: $font-main;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: $color-green;
  text-decoration: underline;
  text-align: left;
  cursor: pointer;
  user-select: none;
  transition: color $transition,;

  &:hover {
    color: $color-green;
    text-decoration: none;
  }

  &_md {
    font-weight: 400;
    font-size: 0.9375rem;
    text-decoration: none;
  }
}
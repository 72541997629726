@import './headerTop/header-top.scss';
@import './headerMiddle/header-middle.scss';
@import './headerBottom/header-bottom.scss';

.header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 3.125rem;
  box-shadow: 0 0.3125rem 0.625rem rgba(0, 0, 0, 0.25);
  background-color: $color-blue;
}

.header__link {
  display: block;
  margin-left: auto;

  svg {
    display: block;
    width: 2rem;
    height: 2rem;
  }
}

.header__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 3.5rem;
}

.nav {
margin-left: auto;
}

.nav__list {
  display: flex;
  margin-bottom: 0;
}

.nav__item {
  margin-left: 1rem;
}

.nav__link {
  font-size: 1.125rem;
  line-height: 1;
  color: $color-white;
  text-decoration: none;

  &:focus, &:hover {
    color: $color-white;
  }
}

@include media-tablet {
  .header {
    position: static;
  }
}
.form-select-hash-container {
  .form-select {
    &__value-container {
      padding-right: 0 !important;
    }

    &__menu {
      height: auto !important;

      .form-select__menu-list {
        display: flex;
        flex-wrap: wrap;
      }

      .form-select__option {
        margin: 0.25rem !important;
        padding: 0.125rem 0.25rem !important;
        width: auto;
        background-color: #ededed !important;
        border-radius: 0.9375rem !important;

        &:before {
          content: "#";
        }

        &:nth-child(n + 2) {
          border: 0;
        }
      }
    }
  }
}

.notify {
  &__wrapper {
    position: relative;
    margin-left: 0.5rem;
    width: 2.25rem;
    height: 2.25rem;
  }

  &__show-button {
    position: relative;
    width: 2.25rem;
    height: 2.25rem;
    background-color: #ededed;
    border-radius: 50%;

    &.active {
      background-color: #d8d8d8;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      pointer-events: none;
    }

    span {
      position: absolute;
      top: -0.1875rem;
      right: 0.1875rem;
      width: 0.6875rem;
      height: 0.6875rem;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      background-color: #f62434;
      border-radius: 50%;
      font-size: 0.375rem;
      font-weight: 500;
      line-height: 0.6875rem;
      color: #fff;
      text-align: center;
    }
  }

  &__notify-panel {
    position: absolute;
    top: calc(100% + 0.625rem);
    right: 0;
    width: 20rem;
    min-height: 4.625rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    border-radius: 0.3125rem;
    background-color: #fff;
    overflow: hidden;
    z-index: 999;
    box-shadow: 0 4px 8px 8px rgba(128, 128, 128, 0.1);
  }

  &__scrollbar {
    max-height: 18.75rem;
  }

  &__link {
    display: block;
    position: relative;
    width: 100%;

    &:nth-child(n + 2) {
      .notify__notify-item {
        border-top: 2px solid #ededed;
      }
    }
  }

  &__counter {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    padding-right: 1rem;
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-weight: 500;
    color: #808080;

    &--empty {
      justify-content: center;
      width: 100%;
      min-height: 4rem;
      text-align: center;
    }
  }

  &__reset-button {
    margin-left: auto;
    font-size: 0.75rem;
    line-height: 1;
    color: #808080;
    text-decoration: underline;
    transition: color 0.25s;

    &:hover {
      color: #00b956;
    }
  }

  &__notify-item {
    display: flex;
    align-items: flex-start;
    min-height: 4.625rem;
    width: 100%;
    padding-left: 0.5625rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-right: 2.25rem;
    background-color: #fff;
    border: 0;

    img {
      flex-shrink: 0;
      display: block;
      width: 2.5rem;
      height: 2.5rem;
    }

    &.new {
      background-color: rgba(0, 185, 86, 0.05);
      border-left: 0.1875rem solid #00b956;
    }

    &-data {
      flex-shrink: 1;
      padding-left: 0.75rem;

      h3 {
        font-weight: 500;
        font-style: normal;
        font-size: 0.875rem;
        line-height: 1.5;
        color: #000;
        text-align: left;
      }

      p {
        font-size: 0.6875rem;
        line-height: 1.3;
        color: #808080;
        text-align: left;
      }
    }
  }

  &__read-button {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    width: 1rem;
    height: 1rem;

    &:hover {
      svg {
        fill: #00b956;
      }
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
      fill: #808080;
      transition: fill 0.25s;
    }
  }
}

@include media-netbook {
  .notify {
    &__wrapper {
      width: 1.875rem;
      height: 1.875rem;
    }

    &__notify-panel {
      top: calc(100% + 0.375rem);
    }

    &__show-button {
      width: 1.875rem;
      height: 1.875rem;
    }

    &__notify-panel {
      right: 1rem;
    }
  }
}

@include media-mobile {
  .notify {
    &__notify-panel {
      right: 0;
    }
  }
}

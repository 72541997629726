.chat {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

@import "./header";
@import "./body";
@import "./quote";
@import "./footer";

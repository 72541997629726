.card--tag {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0.125rem 0.5rem;
  background: $color-purple;
  border-radius: 1.25rem;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  color: $color-white;

  &:hover {
    .card--tag__reasen-popover {
      opacity: 1;
      visibility: visible;
    }
  }

  &._done {
    background: $color-green;
  }

  svg {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-right: 0.1875rem;
    margin-left: -0.25rem;
  }

  &__reasen-popover {
    position: absolute;
    top: calc(100% + 0.75rem);
    left: -0.5rem;
    width: 10rem;
    background-color: #eeeeee;
    border-radius: 0.3125rem;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0.75rem;
      top: -1rem;
      width: 1rem;
      height: 1rem;
      border: 0.5rem solid transparent;
      border-bottom: 0.5rem solid #eeeeee;
    }
  }
}

.form-check-group {
  display: flex;
}

.form-check-input {
  overflow: hidden;
  width: 0;
  height: 0;
  visibility: hidden;

  &:checked + .form-check-icon-frame {
    border: 1px solid $color-green;
    background-color: $color-green;

    .form-check-icon-image {
      opacity: 1;
    }
  }
}

.form-check-icon-frame {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0;
  border: 1px solid #9f9f9f;
}

.form-check-icon-image {
  display: block;
  width: 1.125rem;
  height: 1.125rem;
  opacity: 0;
}

.form-check-label {
  padding-left: 1rem;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-family: $font-main;
  font-weight: 400;
  color: $color-black;

  a {
    color: $color-green;
    text-decoration: underline;
  }
}

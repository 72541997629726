.rating-filter {
  display: flex;
  flex-grow: 1;

  > * {
    margin-left: 0.625rem;
  }

  &__city {
    flex-grow: 1;
    // width: 14.375rem;
  }

  &__period {
    flex-shrink: 0;
    width: 9.75rem;
  }

  &__apply-button {
    display: none;
  }

  .button--modal-close {
    display: none;
  }
}

@include media-netbook {
  .rating-filter {
    position: fixed;
    top: 6.125rem;
    left: 0;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: calc(100vh - 6.125rem);
    padding-top: 3.25rem;
    padding-right: 0;
    padding-bottom: 4.375rem;
    background-color: $color-white;
    border-radius: 1.25rem 1.25rem 0 0;
    background-image: url("/assets/images/bg-filter-mobi@2x.png");
    background-size: 18.75rem auto;
    background-position: 50% 12rem;
    background-repeat: no-repeat;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    z-index: 20;

    &--show {
      display: flex;
    }

    > * {
      margin-left: 0;
    }

    &__city {
      flex-grow: 0;
      margin-bottom: 0.875rem;
      width: 22.1875rem;
    }
    &__period {
      width: 22.1875rem;
    }

    &__apply-button {
      display: flex;
      margin-top: auto;
    }

    .button--modal-close {
      display: block;
    }
  }
}

.wishlists {
  flex-grow: 1;
  width: 100%;
  padding-top: 2.5rem;
  padding-bottom: 3.125rem;
  background-color: $color-grey-background;
  overflow: hidden;
}

@include media-netbook {
  .wishlists {
    min-height: 34rem;

    .container {
      padding-left: 0.625rem;
      padding-right: 0.625rem;
    }
  }
}

@include media-mobile {
  .wishlists {
    padding-top: 2.5rem;
    padding-bottom: 3.125rem;

    // &:after {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   bottom: 0;
    //   right: 0;
    //   background: url("/assets/images/decor-wishlists-mobile@2x-min.png") center -8rem /
    //     48rem auto repeat-y;
    //   z-index: 1;
    // }
  }
}

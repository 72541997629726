.quick--categories {
  position: relative;
  margin-top: -5rem;
  padding-top: 8.75rem;
  padding-bottom: 2.125rem;
  background-image: url("../../assets/images/decor_intro_03.png");
  background-size: 12.625rem auto;
  background-position: calc(50% + 29rem) -2rem;
  background-repeat: no-repeat;
  z-index: 1;

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    margin-top: 2.5rem;
  }
}

@include media-netbook {
  .quick--categories {
    margin-top: -7rem;
    padding-top: 7rem;
    padding-bottom: 4rem;
  }
}

@media screen and (max-width: 1060px) {
  .quick--categories {
    background-size: 9.625rem auto;
    background-position: calc(100% + 0.125rem) -1rem;
  }
}

@include media-tablet {
  .quick--categories {
    padding-top: 7rem;
    padding-bottom: 3rem;
  }
}

@include media-mobile {
  .quick--categories {
    margin-top: -4rem;
    padding: 2.375rem 0;
    padding-top: 5rem;
    background-size: 5.625rem auto;
    background-position: calc(100% + 0.125rem) 6rem;

    .container {
      padding-left: 0.625rem;
      padding-right: 0.625rem;
    }

    &__list {
      grid-template-columns: 1fr;
      gap: 1rem;
      margin-top: 1.5rem;
    }
  }
}

@include media-mobile-small {
  .quick--categories {
    &__list {
      gap: 0.75rem;
    }
  }
}

.form-reg {
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@include media-netbook {
  .form-reg {
    &__footer {
      display: flex;
      flex-direction: column;

      .button {
        margin-top: 2rem;
      }
    }
  }
}

@include media-mobile {
}

.form-select-edit__control {
  min-height: 2rem;
  min-height: 2rem !important;
  box-shadow: 0 !important;
  border-radius: 0 !important;
  border-color: transparent !important;
  border-bottom: 1px solid $color-grey-dark !important;

  &--is-focused,
  &--menu-is-open {
    border-color: transparent !important;
    border-bottom: 1px solid $color-green !important;
    box-shadow: none !important;
  }
  &--is-disabled {
    background-color: $color-white !important;
  }
}

.form-select-edit__value-container {
  padding: 0 0.5rem 0 0.5rem !important;
}

.form-select-edit__single-value,
.form-select-edit__placeholder {
  margin: 0 !important;
  font-family: $font-main !important;
  font-weight: 400 !important;
  font-size: 0.9375rem !important;
  line-height: 1.25rem !important;
  white-space: nowrap;
}

.form-select-edit__single-value {
  color: $color-black !important;
}

.form-select-edit__input-container {
  margin: 0 !important;
}

.form-select-edit__placeholder {
  color: $color-grey-dark !important;
  overflow: hidden;
}

.form-select-edit__indicator-separator {
  display: none;
}

.form-select-edit__indicator {
  width: 1rem !important;
  height: 1rem !important;
  padding: 0 !important;

  svg {
    width: 100% !important;
    height: 100% !important;
    fill: $color-grey-dark;
  }
}

.form-select-edit__menu {
  position: relative;
  margin: 0 !important;
  height: 7.5rem !important;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  border-radius: 0 !important;
}

.form-select-edit__menu-notice {
  padding: 0.5rem 0 !important;
  font-size: 14px !important;
}

.form-select-edit__menu-list {
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  max-height: none !important;
}

.form-select-edit__option {
  padding: 0.5rem 0 !important;
  background-color: $color-white !important;
  font-family: $font-main !important;
  font-weight: 400 !important;
  font-size: 0.9375rem !important;
  color: $color-black !important;
  cursor: pointer !important;
  transition: color 0.3s;

  &:nth-child(n + 2) {
    border-top: 1px solid $color-grey-light;
  }

  &--is-selected {
    background-color: $color-white !important;
  }

  &:hover {
    color: $color-green !important;
  }
}

.form-select-edit {
  &__pseudo-input {
    display: flex;
    align-items: center;
    padding: 0 0.5rem 0 0.5rem !important;
    font-weight: 400 !important;
    font-size: 0.9375rem !important;
    line-height: 1.25rem !important;
    color: $color-grey-dark;
    white-space: nowrap;
  }
}

.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  max-width: 100%;
  min-height: 2.25rem;
  padding: 0.5rem 1.25rem 0.375rem;
  background: $color-green;
  box-shadow: none;
  border: 1px solid $color-green;
  border-radius: 1.5rem;
  box-shadow: none;
  font-family: $font-main;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: $color-white;
  text-align: center;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  transition: background-color $transition, border-color $transition,
    color $transition;

  &:hover {
    background: $color-white;
    color: $color-green;
  }

  &_border {
    background-color: $color-white;
    color: $color-green;

    &:hover {
      background: $color-green;
      color: $color-white;
    }
  }

  &_white {
    background-color: $color-white;
    color: $color-black;
    border: 1px solid $color-white;

    &:hover {
      color: $color-green;
    }
  }

  &[disabled] {
    background: $color-grey;
    border-color: $color-grey;
    color: $color-white;
    cursor: default;
    pointer-events: none;
  }
}

// @include media-netbook {
//   .button {
//     min-height: 1.75rem;
//     padding: 0.1875rem 1rem;
//     font-size: 0.75rem;
//   }
// }

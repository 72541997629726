.category--tile {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 10.5rem;
  height: 100%;
  padding: 1.5rem 3.75rem 1.125rem 1.5rem;
  // background: $green-card-gradient;
  // background-image: url("../../assets/images/bg-category-tile.png");
  background-size: 7.5rem auto, contain;
  background-position: 100% 0.8125rem, 0 0;
  background-repeat: no-repeat;
  border-radius: 0;
  // box-shadow: $green-card-shadow;
  color: $color-white;
  overflow: hidden;

  &:nth-child(1) {
    background-image: url("../../assets/images/icon_cat_01.png"),
      url("../../assets/images/bg-category-tile-01.svg");
  }

  &:nth-child(2) {
    background-image: url("../../assets/images/icon_cat_02.png"),
      url("../../assets/images/bg-category-tile-02.svg");
  }

  &:nth-child(3) {
    background-image: url("../../assets/images/icon_cat_03.png"),
      url("../../assets/images/bg-category-tile-03.svg");
  }

  &:hover {
    color: $color-white;

    .category--tile__link {
      color: $color-white;

      span {
        text-decoration: underline;
      }

      svg {
        transform: translateX(0.25rem);
      }
    }
  }

  &__title {
    position: relative;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    // text-transform: uppercase;
    z-index: 1;
  }

  &__description {
    margin-top: auto;
    font-size: 0.875rem;
    line-height: 1.2;
    color: $color-white;
  }

  &__link {
    position: relative;
    display: inline-flex;
    align-items: center;
    min-height: 2rem;
    margin-top: auto;
    padding: 0.375rem 0;
    font-weight: 400;
    font-size: 0.9375rem;
    line-height: 1.25rem;
    color: $color-grey-light;
    text-decoration: none;
    z-index: 1;

    svg {
      position: relative;
      display: block;
      width: 1rem;
      height: 1rem;
      margin-left: 0.25rem;
      transition: transform $transition;
    }
  }

  &__counter {
    display: flex;
    align-items: flex-end;
    margin-top: auto;
    font-size: 0.9375rem;
    line-height: 1.25rem;
    color: $color-white;

    span {
      &:nth-child(2) {
        margin-left: 0.25rem;
        font-size: 1.125rem;
        line-height: 1.25rem;
        font-weight: 500;
      }
    }
  }

  // &__decor {
  //   position: absolute;
  //   top: 0.8125rem;
  //   right: 0.875rem;
  //   display: block;
  //   width: 7.5rem;
  //   height: auto;
  //   z-index: 0;
  // }
}

@include media-netbook {
}

@include media-tablet {
  .category--tile {
    margin-left: auto;
    margin-right: auto;
    width: 17.5rem;
    min-height: 9rem;
    padding: 1rem 1.3125rem;

    &__decor {
      right: 1.3125rem;
      bottom: 50%;
      transform: translateY(50%);
      width: auto;
      height: 4rem;
    }
  }
}

@include media-mobile {
  .category--tile {
    height: auto;
  }
}

@include media-mobile-small {
  .category--tile {
    // align-items: center;
    // justify-content: space-between;
    // flex-direction: row;
    width: 17.5rem;
    min-height: 9rem;

    &__link {
      display: none;
    }

    &__decor {
      // flex-shrink: 0;
      // position: static;
      // right: auto;
      // bottom: auto;
      // transform: translateY(0);
      height: 3.1875rem;
    }
  }
}

.chat-footer {
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  height: 5.75rem;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  padding-left: 2.75rem;
  padding-bottom: 2.5rem;
  // background-color: $color-grey-light;
  border-top: 1px solid $color-grey;

  //
  &__add-button {
    position: absolute;
    top: 0.625rem;
    left: 0.625rem;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;

    &:hover {
      svg {
        fill: $color-green;
      }
    }

    svg {
      width: 100%;
      height: 100%;
      fill: $color-black;
      transition: fill 0.3s;
    }

    input {
      position: absolute;
      width: 1px;
      height: 1px;
      opacity: 0;
      visibility: hidden;
      z-index: -1;
    }
  }

  &__files {
    position: absolute;
    left: 0.75rem;
    bottom: 5.1875rem;
    width: 8.4375rem;
    height: 0;
    background-color: $color-white;
    border-radius: 0.625rem;
    box-shadow: 0 0 0.375rem rgb(0 0 0 / 30%);
    z-index: -1;
    overflow: hidden;
    transition-delay: 0.2s;
    opacity: 0;

    &-wrapper {
      padding: 0 0.875rem;
    }

    label {
      display: block;
      padding: 0.5rem 0;
      font-size: 0.75rem;
      color: $color-black;
      line-height: 1.25rem;
      transition: color 0.25s;
      cursor: pointer;

      &:hover {
        color: $color-green;
      }
    }

    label + label {
      border-top: 1px solid $color-grey;
    }

    input {
      position: absolute;
      width: 1px;
      height: 1px;
      opacity: 0;
      visibility: hidden;
      z-index: -1;
    }
  }

  .chat-footer__add-button:focus + .chat-footer__files {
    height: 4.75rem;
    // height: 8.75rem;
    opacity: 1;
    z-index: 12;
  }

  //
  &__textarea {
    width: 100%;
    height: 2.75rem;
    border: 0;
    background-color: transparent;
    border: 1px solid transparent;
    font-size: 0.875rem;
    line-height: 1.2;
    color: $color-black;
    overflow: hidden;
    resize: none;
    transition: border 0.3s;
  }

  &__send-button {
    position: absolute;
    bottom: 0.25rem;
    right: 2.125rem;
    width: 6.375rem;
    height: 2rem;
    font-size: 0.9375rem;
    font-weight: 500;
    line-height: 1.25rem;
    color: $color-grey-dark;
    transition: 0.3s;

    &:hover {
      color: $color-green;
    }
  }

  &__smile-button {
    position: absolute;
    bottom: 0.25rem;
    right: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;

    &:hover {
      svg {
        fill: $color-green;
      }
    }

    svg {
      width: 1.25rem;
      height: 1.25rem;
      fill: $color-black;
      transition: fill 0.3s;
    }
  }

  &__smiles {
    display: block;
    position: absolute;
    right: 1rem;
    bottom: 2rem;
    width: 15.5rem;
    height: 0;
    padding-bottom: 0;
    background-color: $color-white;
    box-shadow: 0 0 0.375rem rgb(0 0 0 / 30%);
    z-index: 12;
    border-radius: 0.3125rem;
    overflow: hidden;
    transition-delay: 0.2s;
  }

  &__smiles-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    width: 100%;
    padding: 0.5rem;

    button {
      flex-shrink: 0;
      display: block;
      width: 2rem;
      height: 2rem;
      margin: 0.25rem;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
  }
}

.chat-footer__smile-button:focus + .chat-footer__smiles {
  height: 14rem;
  opacity: 1;
}

.card--content {
  margin-top: auto;
  margin-bottom: 0.5rem;

  + * {
    margin-top: 0;
  }

  &__title {
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.5625rem;
    color: $color-black;

    &_sm {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

    span {
      color: $color-green;
    }
  }

  &__descr {
    margin-top: -0.5rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $color-grey-dark;

    b, strong {
      font-weight: 500;
    }

    span {
      color: $color-green;
    }
  }
}
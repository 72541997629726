.card--tile {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.5rem 1.5rem 1.25rem 1.5rem;
  background: $color-white;
  border-radius: 1.25rem;
  box-shadow: $white-card-shadow;
  overflow: hidden;

  &_autoheight {
    height: auto;
  }

  &__link {
    margin-top: auto;
    color: $color-green;

    &:hover {
      color: $color-green;
    }
  }
}
.modal-kruzok {
  &__data {
    padding: 1.5625rem;
    padding-right: 2rem;
    padding-bottom: 0;

    p {
      font-size: 0.9375rem;
      line-height: 1.2;
      color: #000;

      .green {
        font-weight: 400;
        color: #00b956;
      }
    }

    p + p {
      margin-top: 1rem;
    }
  }

  &__title {
    font-size: 1.375rem;
    line-height: 1.1;
    font-weight: 600;
    font-style: normal;
    color: #000;
  }
}

.modal-kruzok__title + p {
  margin-bottom: 1.25rem;
  font-size: 1.0625rem;
  line-height: 1.2rem;
  font-weight: 500;
}

@include media-mobile {
  .modal-kruzok {
    &__data {
      padding: 1.25rem;
      padding-left: 0.625rem;
      padding-right: 0.625rem;
    }

    p {
      font-size: 0.875rem;
    }

    &__title {
      font-size: 1.375rem;
      line-height: 1.1;
      font-weight: 600;
      font-style: normal;
      color: #000;
    }
  }

  .modal-kruzok__title + p {
    margin-bottom: 1.25rem;
    font-size: 0.9375rem;
    line-height: 1rem;
    font-weight: 400;
  }
}

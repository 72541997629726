.chat-extend {
  position: fixed;
  right: 3.875rem;
  bottom: 3.25rem;
  width: 28.5rem;
  height: 29.875rem;
  background-color: $color-white;
  border-radius: 1.25rem;
  overflow: hidden;
  box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.3);
  z-index: 11;

  .chat-header__avatars {
    display: none;
  }
}

.chat-extend-min {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 1.5625rem;
  bottom: 3.5rem;
  height: 3.75rem;
  width: auto;
  padding-right: 1rem;
  padding-left: 0.875rem;
  border: 0;
  background-color: $color-white;
  border-radius: 1.875rem;
  box-shadow: 0px 0.25rem 0.6875rem rgba(128, 128, 128, 0.11);
  z-index: 10;

  .chat {
    width: 0;
  }

  .chat-header {
    min-height: 0;
    height: 2.125rem;
    padding: 0;
    border: 0;
  }

  .chat-header__avatars {
    display: flex;

    &:not(&:empty) {
      margin-right: 1.875rem;
    }
  }

  .chat-header__title {
    display: none;
  }

  .chat-header__count {
    display: none;
  }

  .chat-footer {
    opacity: 0;
  }
}

@include media-netbook {
  .chat-extend {
    bottom: 6.5rem;
  }
}

@include media-mobile-medium {
  .chat-extend {
    right: 0.5rem;
    bottom: 4rem;
    width: calc(100% - 1rem);
  }

  .chat-extend-min {
    right: 1rem;
    width: auto;
  }
}

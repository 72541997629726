.intro {
  position: relative;
  padding: 6rem 0;
  padding-top: 11.25rem;
  background-image: url("../../assets//images/decor_intro_01.png"),
    url("../../assets//images/decor_intro_02.png");
  background-size: 7.0625rem auto, 36.625rem auto;
  background-position: calc(50% - 30rem) 2.5rem, calc(50% + 21rem) 3.75rem;
  background-repeat: no-repeat, no-repeat;

  .container {
    position: relative;

    > * {
      position: relative;
      z-index: 2;
    }

    // &:after {
    //   content: "";
    //   position: absolute;
    //   top: -4.4375rem;
    //   right: 1.875rem;
    //   width: 30rem;
    //   height: 30.4375rem;
    //   background: url("/assets/images/decor-intro-desktop@2x-min.png") 0 0 /
    //     100% auto no-repeat;
    //   z-index: 1;
    // }
  }

  &__headline {
    position: relative;
    width: 40rem;
    font-size: 2.5rem;
    color: #272727;
    font-weight: 600;
    text-transform: uppercase;
  }

  &__descr {
    max-width: 22.5rem;
    margin-top: 1.125rem;
    font-weight: 500;
    font-size: 1.3125rem;
    line-height: 1.5625rem;
    color: $color-black;
    letter-spacing: 0.1px;
  }

  &__button {
    margin-top: 2rem;
    padding: 0.5rem 2rem;
  }
}

@media screen and (max-width: 1060px) {
  .intro {
    padding-top: 10.25rem;
    background-size: 7.0625rem auto, 30.625rem auto;
    background-position: calc(50% - 30rem) 2.5rem, calc(100% - 1.25rem) 6.5rem;
  }
}

@include media-netbook {
  .intro {
    padding-bottom: 4rem;

    &__headline {
      position: relative;
      width: 39.375rem;
      font-size: 2.5rem;
      // color: #00b956;
      font-weight: 600;
      text-transform: uppercase;

      span {
        font-size: 2rem;
        line-height: 500;
        line-height: 1.1;
        color: #000000;
      }
    }
  }
}

@include media-tablet {
  .intro {
    padding-top: 7rem;
    padding-bottom: 3rem;
    background-size: 5.5rem auto, 20.625rem auto;
    background-position: 0.25rem 0.75rem, calc(100% - 1.25rem) 8.5rem;
  }
}

@include media-mobile {
  .intro {
    min-height: 38.5625rem;
    padding: 2.4375rem 0;
    padding-top: 6rem;
    background-position: 0.25rem 0.75rem, calc(100% - 1.25rem) 18.5rem;

    &__headline {
      position: relative;
      width: 29rem;
      font-size: 1.875rem;
      // color: #00b956;
      font-weight: 600;
      text-transform: uppercase;

      span {
        font-size: 1.625rem;
        line-height: 500;
        line-height: 1;
        color: #000000;
      }

      &:after {
        top: -1.8125rem;
        right: 6.25rem;
        width: 4.8125rem;
        height: 4.25rem;
        background-image: url("../../assets/images/headline-decor@2x-min.png");
      }
    }

    &__descr {
      max-width: 90%;
      margin-top: 1.375rem;
      font-size: 1.125rem;
      line-height: 1.375rem;
      letter-spacing: 0;
    }

    &__button {
      margin-top: 1.4375rem;
    }
  }
}

.card--icon {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: $color-green-light;
  border-radius: 50%;
  color: $color-green;

  img,
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: auto;
    height: 2rem;
  }
}
.headline {
  font-family: $font-main;
  font-weight: 500;
  letter-spacing: 0.326451px;
  color: $color-black;

  span {
    color: $color-green;
  }

  &_xl {
    font-weight: 600;
    font-size: 3rem;
    line-height: 3.5rem;
  }
  &_lg {
    font-weight: 600;
    font-size: 2.5rem;
    line-height: 3rem;
  }
  &_md {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.8125rem;
  }
  &_sm {
  }
  &_xs {
  }
}

@include media-netbook {
  .headline {
    &_xl {
      font-size: 2.5rem;
      line-height: 3rem;
    }
    &_lg {
      font-size: 1.875rem;
      line-height: 2.25rem;
    }
  }
}

@include media-mobile-small {
  .headline {
    &_xl {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    &_md {
      font-size: 1.3125rem;
      line-height: 1.5625rem;
    }
  }
}
.wish-notify {
  &__quick-notify {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 0.5rem;
    padding-right: 0.625rem;

    &:nth-child(n + 2) {
      border-top: 1px solid rgba(255, 255, 255, 0.2);
    }

    &-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: #731982;
      z-index: 2;
    }

    &-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 75.5rem;
      padding: 0.5rem 0;

      img {
        flex-shrink: 0;
        display: block;
        width: 1.5rem;
        height: 1.5rem;
      }

      div {
        flex-shrink: 1;
      }

      span,
      b {
        margin-left: 0.375rem;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.4;
        color: #fff;
      }

      b {
        font-weight: 500;
      }
    }
  }
}

@include media-tablet {
  .wish-notify {
    &__quick-notify {
      &-wrapper {
        img {
          display: block;
          width: 1.5rem;
          height: 1.5rem;
        }

        div {
          display: flex;
          flex-direction: column;
          padding-left: 0.375rem;
        }

        span,
        b {
          margin-left: 0;
          font-size: 0.75rem;
        }
      }
    }
  }
}

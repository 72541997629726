.profile-wish {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  &__wrapper {
    display: flex;
    width: 58.5625rem;
    padding-top: 2.125rem;
    padding-bottom: 1.3125rem;
  }

  &__form {
    width: 23.6875rem;
  }

  &__form-wrapper {
    margin-top: 1rem;
    padding-top: 1.9375rem;
    padding-bottom: 1.1875rem;
    background-color: $color-white;
    border-radius: 1.25rem;
  }

  &__title {
    margin-bottom: 2.125rem;
    font-weight: 500;
    font-size: 1.3125rem;
    line-height: 1.5625rem;
    color: $color-black;
    text-align: center;
  }

  &__history {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    width: 28.5rem;
    padding-top: 2.25rem;
  }
}

@include media-tablet {
  .profile-wish {
    &__wrapper {
      flex-direction: column;
      width: 28.5rem;
      padding-top: 2.375rem;
      padding-bottom: 2.5rem;
    }

    &__form {
      width: 100%;
    }

    &__form-wrapper {
      width: 100%;
    }
  }
}

@include media-mobile-medium {
  .profile-wish {
    &__wrapper {
      width: 100%;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }

    &__history {
      width: 100%;
    }
  }
}

.birthday--wishlist {
  position: relative;
  padding-top: 3.75rem;
  padding-bottom: 5rem;

  .container {
    position: relative;
    padding-left: 15.75rem;

    > * {
      position: relative;
      z-index: 2;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0rem;
      left: -6.5rem;
      width: 22.3125rem;
      height: 23.1875rem;
      background: url("/assets/images/decor-birthday-desktop@2x-min.png") 0 0 /
        auto 100% no-repeat;
      z-index: 1;
    }
  }

  &__headline {
    margin-bottom: 1.25rem;
  }

  &__list {
    width: 58rem;
    width: 100%;
    overflow: visible !important;
  }

  &__navigation {
    margin-top: 2rem;
    margin-right: -7rem;
  }

  &__arrow--prev {
    order: 1;
  }

  &__arrow--next {
    order: 3;
  }

  &__dots {
    order: 2;
  }

  &__arrow--prev,
  &__arrow--next {
    // display: none;
  }
}

@include media-netbook {
  .birthday--wishlist {
    padding-top: 4rem;
    padding-bottom: 4rem;

    .container {
      &:after {
        top: 0rem;
        left: -5.5rem;
        width: 25rem;
        height: 22.25rem;
      }
    }
  }
}

@include media-tablet {
  .birthday--wishlist {
    padding-top: 3rem;
    padding-bottom: 3rem;

    .container {
      padding-left: 35vw;
    }
  }
}

@include media-mobile {
  .birthday--wishlist {
    padding: 2.375rem 0;

    .container {
      padding-left: 0.625rem;
      padding-right: 0.625rem;
      padding-bottom: 15.75rem;

      &:after {
        content: "";
        position: absolute;
        top: auto;
        bottom: -2rem;
        left: 0rem;
        width: 26.3125rem;
        height: 19.3125rem;
        background: url("/assets/images/decor-birthday-desktop@2x-min.png") 0 0 /
          auto 100% no-repeat;
        z-index: 1;
      }
    }

    &__headline {
      margin-bottom: 0.9375rem;
    }

    &__navigation {
      justify-content: flex-end;
      margin-top: 1.625rem;
      margin-right: 0;
      padding: 0 0.875rem;
    }

    &__arrow--prev,
    &__arrow--next {
      display: flex;
    }
  }
}

@include media-mobile-small {
  .birthday--wishlist {
    .container {
      padding-bottom: 16.75rem;

      &:after {
        left: 0rem;
      }
    }
  }
}

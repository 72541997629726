.form-wish-profile {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__container {
    width: 17.625rem;
  }

  &__act-button {
    padding: 0.5rem 1rem;
  }

  textarea {
    min-height: 5rem;
  }
}

.form-wish-profile__act-button + .form-wish-profile__act-button {
  margin-left: 0.5rem !important;
}

.new--wishlist {
  position: relative;
  padding-top: 6.75rem;
  padding-bottom: 1.125rem;
  z-index: 2;

  &__headline {
    margin-bottom: 0.625rem;
  }

  &__counter {
    margin-bottom: 2.625rem;
    padding: 0;
    justify-content: flex-start;
    background-color: transparent;
    text-transform: uppercase;

    .total-counter__title {
      font-size: 2rem;
      line-height: 1;
      font-weight: 600;
      color: #00b956;
    }

    .total-counter__quantity {
      margin-left: 0.5rem;
      font-weight: 600;
      font-size: 2rem;
      line-height: 1;
      color: #731982;
    }
  }

  &__list {
    overflow: visible !important;
  }

  &__navigation {
    margin-top: 2.6875rem;
  }

  &__arrow--prev {
    order: 1;
  }

  &__arrow--next {
    order: 3;
  }

  &__dots {
    order: 2;
  }
}

@include media-netbook {
  .new--wishlist {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

@include media-tablet {
  .new--wishlist {
    padding-top: 3rem;
    padding-bottom: 3rem;

    &__counter {
      // margin-bottom: 2.625rem;
      padding: 0;
      justify-content: flex-start;
      background-color: transparent;

      span {
        font-size: 1.625rem;
        line-height: 1;
        color: #00b956;
        text-transform: uppercase;
      }
    }
  }
}

@include media-mobile {
  .new--wishlist {
    padding: 2.4375rem 0;

    .container {
      padding-left: 0.625rem;
      padding-right: 0.625rem;
    }

    &__headline {
      margin-bottom: 0.25rem;
      letter-spacing: 0;
    }

    &__counter {
      margin-bottom: 1rem;
    }

    &__navigation {
      justify-content: flex-end;
      margin-top: 1.625rem;
      padding: 0 0.875rem;
    }
  }
}

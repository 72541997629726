.empty-notice {
  flex-grow: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-top: 2rem;
  padding-bottom: 2rem;

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 49.875rem;
    min-height: 37rem;
    padding-bottom: 2.75rem;
    border-radius: 1.25rem;
    background-color: $color-white;
    overflow: hidden;
    z-index: 1;
  }

  &__image {
    display: block;
    width: 49.875rem;
    height: 20.875rem;

    img {
      display: block;
      width: 100%;
    }
  }

  &__data {
    margin-top: 2.1875rem;
    padding-left: 2.25rem;
    padding-right: 7.5rem;
    padding-bottom: 1.875rem;
  }

  &__title {
    margin-bottom: 1.5rem;
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 600;
    font-style: normal;
    color: $color-black;
  }

  &__subtitle {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: $color-black;
  }

  &__buttons {
    display: flex;
    align-items: center;
    margin-top: auto;
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  &__link {
    display: flex;
    align-items: center;
    margin-left: 1.375rem;

    span {
      font-size: 0.875rem;
      line-height: 1.375rem;
      color: $color-grey-dark;
      transition: color 0.3s;
    }

    svg {
      display: block;
      width: 1rem;
      height: 1rem;
      fill: $color-grey-dark;
      transition: fill 0.3s;
    }

    &:hover {
      span {
        color: $color-green;
      }

      svg {
        fill: $color-green;
      }
    }
  }
}

@include media-netbook {
  .empty-notice {
    &__title {
      font-size: 1.5rem;
      line-height: 1.8125rem;
    }

    &__subtitle {
      font-size: 0.9375rem;
      line-height: 1.375rem;
    }

    &__container {
      min-height: 33.8125rem;
      padding-bottom: 1.8125rem;
    }

    &__data {
      margin-top: 2rem;
      padding-bottom: 1.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    &__buttons {
      padding: 0 1rem;
    }
  }
}

@include media-mobile-small {
  .empty-notice {
    &__image {
      display: block;
      width: 22.1875rem;
      height: 17.1875rem;
    }
  }
}

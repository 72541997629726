.form-label {
  margin: 0;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.25rem;
  letter-spacing: -0.24px;
  color: $color-black;
}

.form-control-relative-wrapper {
  position: relative;
}

.form-control-relative-scroller {
  position: relative;
  height: 6.25rem;
  width: calc(100% + 0.5rem);

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: calc(100% + 0.5rem);
    width: 100%;
    height: 1px;
    background-color: #808080;
  }

  .form-control-edit-button {
    right: 1rem;
  }

  textarea {
    padding-right: 2.5rem;
    border-bottom: 0 !important;
  }
}

.--editable .form-control-relative-scroller {
  &::after {
    background-color: #00b956;
  }
}

.form-control-relative-scroller + .input__error-text {
  padding-top: 0.75rem;
}

.form-control {
  padding: 0.8125rem 0.4375rem 0.8125rem 0.9375rem;
  background: $color-white;
  border: 1px solid $color-grey;
  border-radius: 0.3125rem;
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.25rem;
  letter-spacing: -0.24px;
  color: $color-black;

  &::placeholder {
    color: $color-grey-dark;
    opacity: 1;
  }

  &:hover {
    border-color: #a2a2a2;
  }

  &:focus {
    color: $color-black;
    background-color: $color-white;
    border-color: #00b956;
    outline: 0;
    box-shadow: none;
  }

  &[disabled] {
    background: #f2f2f2;
    color: #999999;
    border-color: #e6e6e6;
  }

  &.error {
    border-color: $color-red;
    box-shadow: 0 0 0.25rem #f24822;
  }

  &.success {
    border-color: $color-green;
    box-shadow: 0 0 0.25rem #3de959;
  }
}

.form-control-template {
  border: 0;
  opacity: 0;
}

.form-control-repeater {
  // position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  // height: 100% !important;
  overflow: hidden !important;
}

.form-control-smaller-text .form-control {
  font-size: 0.875rem;
  line-height: 1.3125rem;
}

.form-control-passw-button {
  display: block;
  position: absolute;
  right: 0.5rem;
  top: 1rem;
  margin: 0;
  width: 1rem;
  height: 1rem;
  padding: 0;
  border: 0;
  background-color: transparent;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.form-control-edit-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1rem;
  height: 1rem;

  &.disabled {
    svg {
      fill: $color-grey-dark;
    }
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: $color-green;
  }
}

.form-control-search-button {
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  width: 1.5rem;
  height: 1.5rem;

  svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: $color-grey-dark;
  }
}

.form-control-search-icon {
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  width: 1.5rem;
  height: 1.5rem;

  svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: $color-grey-dark;
  }
}

.form-profile-avatar {
  &__label {
    position: relative;
    width: 6.75rem;
    height: 6.75rem;
    border-radius: 50%;
    background-color: $color-grey-light;
    cursor: pointer;
    border-radius: 50%;

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 0.0625rem;
      height: 0.0625rem;
      opacity: 0;
      overflow: hidden;
      visibility: hidden;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
      overflow: hidden;
    }

    span {
      position: absolute;
      right: 0.75rem;
      bottom: 0.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.1875rem;
      height: 1.1875rem;
      border: 0.125rem solid $color-white;
      border-radius: 50%;
      background-color: $color-green;

      svg {
        display: block;
        width: 0.75rem !important;
        height: 0.75rem !important;
        fill: $color-white;
      }
    }
  }
}

@include media-mobile-medium {
  .form-profile-avatar {
    &__label {
      width: 5rem;
      height: 5rem;

      span {
        right: 0.125rem;
        bottom: 0.125rem;
      }
    }

    &__textarea {
      height: 6.75rem;
    }
  }
}

.form-profile-user {
  &__form-control {
    height: 2rem;
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
    padding-left: 0.5rem;
    padding-right: 2rem;
    border-color: transparent;
    border-radius: 0;
    border-bottom: 1px solid $color-green;
    color: $color-black;
  }

  &__textarea {
    height: auto;
    min-height: 5.625rem;
    padding: 0.25rem 2rem 0.75rem 0.5rem;
    border-color: transparent;
    border-radius: 0;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: $color-black;
    resize: none;
    overflow: auto;
    border-bottom: 1px solid $color-green;
    color: $color-black;
  }

  &__textscroll {
    position: relative;
    height: 6.25rem;
    min-height: 6.25rem;
    padding: 0;
    border-color: transparent;
    border-radius: 0;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: $color-black;
    resize: none;
    overflow: hidden;
    border-bottom: 1px solid $color-green;
    color: $color-black;
  }

  &__form-control,
  &__textarea {
    &:hover,
    &:focus {
      border-color: transparent;
      border-bottom: 1px solid $color-green;
      color: $color-black;
    }

    &:disabled {
      border-color: transparent;
      border-bottom: 1px solid $color-grey-dark;
      background-color: transparent;
      // color: $color-grey-dark;
      color: $color-black;
      user-select: none;
      pointer-events: none;
    }
  }
}

@include media-mobile-medium {
  .form-profile-user {
    &__textarea {
      min-height: 6.625rem;
    }
  }
}

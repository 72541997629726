.wish-makers {
  display: flex;
  align-items: flex-start;
  padding: 2rem;
  background-color: $color-white;
  border-radius: 1.25rem;

  &__avatar {
    flex-shrink: 0;
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 50%;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__info {
    margin-left: auto;
    width: 15.75rem;
    color: $color-black;
  }

  &__title {
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    line-height: 1;
  }

  &__name {
    width: 100%;
    font-size: 1.3125rem;
    font-weight: 500;
    line-height: 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__city,
  &__amount {
    margin-top: 0.5625rem;
    font-size: 0.875rem;
    line-height: 1.25rem;

    span {
      color: $color-grey-dark;
    }
  }

  &__no-performers {
    max-width: 10rem;
    font-size: 1.3125rem;
    line-height: 1.5rem;
    font-weight: 500;
    color: $color-grey-dark;
  }

  &__slider {
    margin-bottom: -1.5rem;
    width: 100%;

    .swiper-wrapper {
      height: auto;
    }

    .swiper--arrow {
      display: none;
    }
  }
}

@include media-mobile {
  .wish-makers {
    &__avatar {
      width: 5rem;
      height: 5rem;
    }

    &__info {
      width: 10.75rem;
    }
  }
}

.button--modal-close {
  position: absolute;
  top: 0.8125rem;
  right: 1.1875rem;
  width: 0.9375rem;
  padding: 0;
  height: 0.9375rem;
  border: 0;
  background-color: transparent;
  z-index: 5;

  svg {
    display: block;
    width: 100%;
    height: 100%;
    stroke: $color-black;
  }
}

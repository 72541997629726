.chat-header {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  min-height: 3.125rem;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid $color-grey;

  &__title {
    font-weight: 18;
    font-weight: 500;
    line-height: 1;
    color: $color-black;
  }

  &__avatars {
    display: flex;
  }

  &__avatar {
    position: relative;
    width: 2.125rem;
    height: 2.125rem;

    &--has-message {
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 0.5rem;
        height: 0.5rem;
        border: 1px solid $color-white;
        border-radius: 50%;
        background-color: $color-green;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
      }
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
      overflow: hidden;
    }

    &:nth-child(1) {
      z-index: 10;
    }

    &:nth-child(2) {
      margin-left: -0.75rem;
      z-index: 9;
    }

    &:nth-child(3) {
      margin-left: -0.75rem;
      z-index: 8;
    }
  }

  &__members {
    display: flex;
    margin-left: auto;
  }

  &__members-title {
    margin-right: 0.25rem;
    font-size: 0.875rem;
    line-height: 1;
    color: $color-grey-dark;
  }

  &__members-names {
    max-width: 12.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1;
    color: $color-black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__count {
    margin-left: auto;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $color-grey-dark;

    span: {
      margin-left: 0.25rem;
      font-weight: 500;
      color: $color-black;
    }
  }
}

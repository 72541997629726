.total-counter {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 11.25rem;
  padding: 0.75rem 1.25rem;
  border-radius: 1.375rem;
  background-color: $color-white;
  letter-spacing: -0.32px;

  &__title {
    font-size: 2rem;
    line-height: 1;
    font-weight: 600;
    color: $color-grey-dark;
  }

  &__quantity {
    margin-left: 0.5rem;
    font-weight: 600;
    font-size: 2rem;
    line-height: 1;
    color: #00b956;
  }
}
